<template>
  <div :class="{'is-collapse': isCollapse}">
    <template v-for="(item) in list">
      <el-submenu v-if="hasChildren(item.children) && item.hasPermission" :key="item.index" :index="item.path" :unique-opened="true">
        <template slot="title">
          <img v-if="item.icon" class="icon" :src="require(`@/assets/images/menu/${item.icon}.svg`)">
          <img v-if="item.icon" class="icon active" :src="require(`@/assets/images/menu/${item.icon}_active.svg`)">
          <span v-if="!isCollapse" slot="title">{{ item.title }}</span>
        </template>
        <MenuTree :list="item.children" />
      </el-submenu>
      <el-menu-item v-if="!hasChildren(item.children) && !item.hideInMenu && item.hasPermission" :key="item.index" :index="item.path" @click="goPath(item, item.isExternalPath);">
        <img v-if="item.icon" class="icon" :src="require(`@/assets/images/menu/${item.icon}.svg`)">
        <img v-if="item.icon" class="icon active" :src="require(`@/assets/images/menu/${item.icon}_active.svg`)">
        <span v-if="!isCollapse" slot="title">{{ item.title }}</span>
      </el-menu-item>
    </template>
  </div>
</template>

<script>
import { getToken, getUserId, getCompanyId } from '@/utils/auth';
import { mapMutations } from 'vuex';
import { productId } from '@/config/settings';
// import { pathToName } from '@/assets/js/libs';

export default {
  name: 'MenuTree',
  props: {
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapMutations({
      addNav: 'common/addNav',
      addAlive: 'common/addAlive',
      removeAlive: 'common/removeAlive',
    }),
    externalJump(path) {
      const dataToken = getToken();
      const userId = getUserId();
      const companyId = getCompanyId();
      const companyName = this.$store.state.common.userInfo.h;
      window.open(`${path.path}?token=${dataToken}&company=${companyName}&a=${userId}&b=${productId}&c=${companyId}`);
    },
    goPath(path, isExternalPath) {
      if (isExternalPath) {
        this.externalJump(path);
      } else {
        // this.removeAlive(pathToName(path.path));
        // this.$nextTick(() => {
        // this.addAlive(pathToName(path.path));
        this.addNav(path);
        // this.$router.push({
        //   path: path.path,
        //   query: path.query,
        // });
        this.$router.push({
          path: path.path,
          query: Object.assign(path.query || {}, {
            removeAlive: true,
          }),
        });
        // });
      }
    },
    hasChildren(list) {
      if (list && list.length > 0) {
        let useFul = false;
        list.some((val) => {
          if (!val.hideInMenu) {
            useFul = true;
          }
        });
        return useFul;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss">
.is-collapse {
  .el-submenu__title {
    padding: 0 18px !important;
    .icon {
      width: 20px;
      height: 20px;
    }
  }
}
</style>

<style lang="scss" scoped>
.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  &.active {
    display: none;
  }
}
</style>
