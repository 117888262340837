const basicData = [
  {
    meta: {
      code: '310',
      keepAlive: true,
    },
    name: '产品管理',
    path: '/basicData/product',
    component: () => import('@/views/basicData/product'),
  },
  {
    meta: {
      code: '320',
      keepAlive: true,
    },
    name: '常用地址',
    path: '/basicData/usualAddress',
    component: () => import('@/views/basicData/usualAddress'),
  },
  {
    meta: {
      code: '330',
      keepAlive: true,
    },
    name: '常发线路',
    path: '/basicData/businessLine',
    component: () => import('@/views/basicData/businessLine'),
  },
  {
    meta: {
      code: '340',
      keepAlive: true,
    },
    name: '驾驶员',
    path: '/basicData/driver',
    component: () => import('@/views/basicData/driver'),
  },
  {
    meta: {
      code: '350',
      keepAlive: true,
    },
    name: '押运员',
    path: '/basicData/escort',
    component: () => import('@/views/basicData/escort'),
  },
  {
    meta: {
      code: '360',
      keepAlive: true,
    },
    name: '车辆',
    path: '/basicData/truck',
    component: () => import('@/views/basicData/truck'),
  },
  {
    meta: {
      code: '370',
      keepAlive: true,
    },
    name: '挂车',
    path: '/basicData/trailer',
    component: () => import('@/views/basicData/trailer'),
  },
  {
    meta: {
      code: '380',
      keepAlive: true,
    },
    name: '运力组合',
    path: '/basicData/capacity',
    component: () => import('@/views/basicData/capacity'),
  },
];

export default basicData;
