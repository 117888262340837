import request from '@/plugins/axios';
function pickTrue(obj) {
  const newObj = {
    ...obj,
  };
  Object.keys(newObj).forEach((item) => {
    if (!newObj[item] && newObj[item] !== 0) {
      delete newObj[item];
    }
  });
  return newObj;
}
const prefix = '/auv';
export default {
  getList: function(params) {
    return request.get(`${prefix}/order/car/detail`, pickTrue(params));
  },
  updatePoundBill: function(params) {
    return request.post(`${prefix}/order/car/finish`, params);
  },
  // 磅单图片批量下载
  downloadPoundBill: function(params) {
    return request.post(`${prefix}/order/car/qx/receipt/download`, pickTrue(params), { showLoading: true });
  },
  // 磅单图片批量下载任务列表
  downloadPoundBillList: function(params) {
    return request.post(`${prefix}/order/car/qx/receipt/download/list`, pickTrue(params));
  },
  // 磅单图片批量下载任务删除
  deldownloadPoundBill: function(params) {
    return request.post(`${prefix}/order/car/qx/receipt/download/del`, pickTrue(params));
  },
  // 根据状态查询任务数量
  deldownloadPoundNum: function(params) {
    return request.post(`${prefix}/order/car/qx/receipt/download/state`, pickTrue(params));
  },
};

