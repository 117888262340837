<template>
  <el-form ref="form" :model="model" v-bind="$attrs" class="search-form">
    <div class="collapse">
      <el-row :gutter="20">
        <slot />
        <template v-if="showSearch">
          <slot name="hide" />
        </template>
        <!-- :xs="24" :sm="12" :md="8" :lg="6" -->
        <el-col :xs="span1" :sm="span1" :md="span1" :lg="span2" :xl="span" class="footer">
          <!-- <table-export v-if="isExport" :module-key="moduleKey" :export-name="exportName + exportDate + '.xlsx'" style="margin-right: 10px" :export-condition="exportCondition" /> -->
          <!-- {{ span2 }}/{{ span1 }}/{{ span }} -->
          <el-button type="primary" @click="onSubmit">查询</el-button>
          <el-button type="default" @click="reset">重置</el-button>
          <el-button v-if="collapse" type="text" @click="switchSearch">
            <div v-if="showSearch" class="main-c">
              <span>收起</span>
              <i class="el-icon-caret-top" />
            </div>
            <div v-else class="main-c">
              <span>展开</span>
              <i class="el-icon-caret-bottom" />
            </div>
          </el-button>
        </el-col>
      </el-row>
    </div>
  </el-form>
</template>

<script>
// import TableExport from '^/TableExport';
import dayjs from 'dayjs';
import { parseTime } from '@/utils';
export default {
  name: 'SearchForm',
  // components: { TableExport },
  componentName: 'SearchForm',
  filters: {
    parseTime,
  },
  props: {
    /* 导出文件名称 */
    exportCondition: {
      type: Object,
      default: () => {},
    },
    /* 导出文件名称 */
    exportName: {
      type: String,
      default: '',
    },
    /* 模板key */
    moduleKey: {
      type: String,
      default: '',
    },
    /* 是否显示导出按钮 */
    // isExport: {
    //   type: Boolean,
    //   default: false,
    // },
    model: {
      type: Object,
      default: () => {},
    },
    collapse: {
      type: Boolean,
      default: true,
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    const exportDate = parseTime(dayjs().valueOf(), 'YYYYMMDDHHmm');
    return {
      showSearch: true,
      collapseFixed: false,
      items: {},
      fields: {},
      defaultValues: {},
      exportDate: exportDate,
    };
  },
  computed: {
    span2() {
      if (this.collapse && !this.showSearch) {
        const length = this.$slots.default?.length;
        if (length) {
          return (3 - (length % 3)) * 8;
        }
        return 8;
      }
      return (3 - (this.count % 3)) * 8;
    },
    span() {
      if (this.collapse && !this.showSearch) {
        const length = this.$slots.default?.length;
        if (length) {
          return (4 - (length % 4)) * 6;
        }
        return 6;
      }
      return (4 - (this.count % 4)) * 6;
    },
    span1() {
      if (this.showSearch && this.count % 2 === 1) {
        return 12;
      }
      return 24;
    },
  },
  created() {
    this.$on('searchItemAdded', this.pushItems);
  },
  mounted() {
    this.calcColLength();
    Object.keys(this.model).forEach((key) => {
      this.defaultValues[key] = this.model[key];
    });
  },
  methods: {
    pushItems(item) {
      if (!item.prop) return;
      this.items[item.prop] = item.hide;
      this.fields[item.prop] = '';
    },
    calcColLength() {
      if (this.count && this.count % 3 !== 0) {
        this.collapseFixed = true;
      }
    },
    onSubmit() {
      this.$emit('search');
    },
    reset() {
      this.$emit('reset');
      Object.keys(this.model).forEach((key) => {
        this.model[key] = this.defaultValues[key];
      });
      // this.$refs.form.resetFields();
      this.onSubmit();
    },
    switchSearch() {
      this.showSearch = !this.showSearch;
      if (this.showSearch) {
        this.calcColLength();
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      } else {
        this.collapseFixed = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 22px;
  &.fixed {
    position: absolute;
    right: 24px;
    bottom: 24px;
    margin-bottom: 0;
  }
}
.search-form {
  background: white;
  padding: 24px 24px 2px;
  //border-radius: 0 0 6px 6px !important;
  border-radius: 4px !important;
  position: relative;
}
::v-deep {
  .el-row {
    display: flex;
    flex-wrap: wrap;
  }
  .el-col .el-col {
    padding: 0 !important;
  }
  .el-col {
    .el-col + .el-col {
      margin-left: 10px;
    }
  }
  .el-form-item__content {
    display: flex;
  }
}
</style>
