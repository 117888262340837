const basicData = [
  {
    meta: {
      code: '220',
      keepAlive: true,
    },
    name: '个人用户管理',
    path: '/user/personal',
    component: () => import('@/views/user/personal'),
  },
  {
    meta: {
      code: '220',
      keepAlive: true,
    },
    name: '个人用户详情',
    path: '/user/personal/detail',
    component: () => import('@/views/user/personal/detail'),
  },
];

export default basicData;
