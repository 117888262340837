<template>
  <div class="layout-index-wrapper">
    <el-container class="layout-container">
      <el-header class="layout-header-container">
        <Header />
      </el-header>
      <el-container class="layout-main-container">
        <el-aside class="aside-container">
          <Aside />
        </el-aside>
        <el-main class="main-container">
          <div class="nav-container">
            <Nav />
          </div>
          <div class="router-view-container">
            <keep-alive :include="cachedViews">
              <router-view />
            </keep-alive>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <PageLoading class="layout-loading-container" />
    <FloatBall />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Header from '@/components/layout/header';
import Aside from '@/components/layout/aside';
import Nav from '@/components/layout/nav';
import PageLoading from '@/components/layout/pageLoading';
import FloatBall from '@/components/layout/floatBall';
export default {
  components: {
    Header,
    Aside,
    Nav,
    PageLoading,
    FloatBall,
  },
  computed: {
    ...mapState({
      cachedViews() { return this.$store.state.common.aliveList.join(','); },
    }),
  },
};
</script>

<style lang="scss" scoped>
.layout-index-wrapper {
  height: 100%;
  .layout-container {
    height: 100%;
  }
  .layout-header-container {
    padding: 0;
    height: 48px !important;
  }
  .layout-main-container{
    .aside-container {
      width: auto !important;
      max-width: 200px;
    }
    .main-container {
      padding: 0;
      height: 100%;
      .nav-container {
        height: 36px;
      }
      .router-view-container {
        height: calc(100vh - 94px);
        overflow-y: auto;
        padding: 15px 16px;
        background-color: #f5f7fa;
      }
    }
  }
}
</style>
