import Vue from 'vue';
import VueRouter from 'vue-router';
import Layout from '@/components/layout/index.vue';
import Common from './common';

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      name: '登录',
      path: '/login',
      component: () => import('@/views/login'),
    },
    {
      name: '忘记密码',
      path: '/forget',
      component: () => import('@/views/forget'),
    },
    {
      path: '/',
      component: Layout,
      children: Common,
    },
    {
      path: '*',
      redirect: '/404',
    },
  ],
});

export default router;
