<template>
  <div v-show="show" class="page-loading-wrap">
    <div>
      <img src="@/assets/images/common/loading.gif">
      <div>加载中...</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapState({
      loading() { return this.$store.state.common.loading; },
    }),
  },
  watch: {
    loading(val) {
      if (val) {
        this.show = true;
      } else {
        setTimeout(() => {
          this.show = false;
        }, 300);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-loading-wrap {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  >div {
    width: 330px;
    height: 132px;
    background-color: #fff;
    box-shadow: 0px 0px 16px 0px rgba(3, 15, 37, 0.1);
    border-radius: 4px;
    text-align: center;
    >img {
      margin-top: 45px;
      width: 153px;
      height: 14px;
    }
    >div {
      margin-top: 8px;
      width: 100%;
      height: 20px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 1px;
      color: $blue;
      text-align: center;
    }
  }
}
</style>
