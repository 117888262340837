import Cookies from 'js-cookie';

const prefix = 'BOSS_';
const TokenKey = prefix + 'AUV_TOKEN';
const UserIdKey = prefix + 'AUV_USER_ID';
const CompanyIdKey = prefix + 'AUV_CPY_ID';
const UserInfoKey = prefix + 'AUV_USER_INFO';

const NavList = prefix + 'AUV_NAV_LIST';

// token
export function getToken() {
  return Cookies.get(TokenKey);
}

export function setToken(token) {
  return Cookies.set(TokenKey, token);
}

export function removeToken() {
  return Cookies.remove(TokenKey);
}

// userId
export function getUserId() {
  return Cookies.get(UserIdKey);
}

export function setUserId(userId) {
  return Cookies.set(UserIdKey, userId);
}

export function removeUserId() {
  return Cookies.remove(UserIdKey);
}
// CompanyIdKey
export function getCompanyId() {
  return Cookies.get(CompanyIdKey);
}

export function setCompanyId(cpyId) {
  return Cookies.set(CompanyIdKey, cpyId);
}

export function removeCompanyId() {
  return Cookies.remove(CompanyIdKey);
}

// UserInfoKey
export function getUserInfo() {
  return Cookies.get(UserInfoKey);
}

export function setUserInfo(userInfo) {
  return Cookies.set(UserInfoKey, userInfo);
}

export function removeUserInfo() {
  return Cookies.remove(UserInfoKey);
}
// NavList
export function getNavList() {
  return Cookies.get(NavList);
}

export function setNavList(navList) {
  return Cookies.set(NavList, navList);
}

export function removeNavList() {
  return Cookies.remove(NavList);
}
