
export default {
  taxMap: { 0: '不含税', 1: '含税' },
  taxArrayMap: [
    { label: '含税', value: 1 },
    { label: '不含税', value: 0 },
  ],
  taxColorMap: { 0: 'small-border-green', 1: 'small-border-yellow' },
  priorityMap: { 1: '极高', 2: '较高', 3: '一般' },
  priorityArrayMap: [
    { label: '极高', value: 1 },
    { label: '较高', value: 2 },
    { label: '一般', value: 3 },
  ],
  priorityColorMap: { 1: 'red-c', 2: 'yellow-c', 3: 'green-c' },
  sourceMap: { 1: '平台优选', 2: '平台定向' },
  carTypeMap: { 1: '自有', 2: '外协' },
  carTypeColorMap: { 1: 'green-c', 2: 'yellow-c' },
  landMap: { 0: '不卸结', 1: '卸结' },
  priceTypeMap: { 1: '运价', 2: '信息费' },
  transportPriceUnitMap: {
    A: [
      { label: '元/吨', value: 1 },
      { label: '元/车', value: 2 },
      { label: '元/公里', value: 3 },
    ],
    O: {
      1: '元/吨',
      2: '元/车',
      3: '元/公里',
    },
  },
  // 货单类型
  // 0自建 1货主直发 3车队外协 4承运商货单
  goodsSourceMap: {
    A: [
      { label: '货主直发', value: 1 },
      { label: '承运商货单', value: 4 },
      { label: '外协货单', value: 3 },
    ],
    O: {
      1: '货主直发',
      4: '承运商货单',
      3: '外协货单',
    },
  },
  // 报车类型
  goodsTypeMap: {
    A: [
      { label: '定价报车', value: 1 },
      { label: '竞价报车', value: 2 },
      // { label: '合约报车', value: 3 },
    ],
    O: {
      1: '定价报车',
      2: '竞价报车',
      // 3: '合约报车',
    },
  },
  // 货单状态
  goodsStateMap: {
    SA: [
      { label: '进行中', value: 7 },
      // { label: '进行中', value: 1 },
      { label: '已报停', value: 8 },
      { label: '运输完成', value: 9 },
      { label: '已关闭', value: 0 },
    ],
    A: [
      { label: '已取消', value: 0 },
      { label: '已接单', value: 1 },
      { label: '待确认', value: 2 },
      { label: '待提交', value: 4 },
      { label: '待确认车号', value: 5 },
      { label: '已确认', value: 6 },
      { label: '运输中', value: 7 },
      { label: '已报停', value: 8 },
      { label: '运输完成', value: 9 },
    ],
    O: {
      0: '已关闭',
      1: '进行中',
      2: '进行中',
      3: '进行中',
      4: '进行中',
      5: '进行中',
      6: '进行中',
      7: '进行中',
      8: '已报停',
      9: '运输完成',
    },
    // O: {
    //   0: '已取消',
    //   1: '已接单',
    //   2: '待确认',
    //   // 3: '进行中',
    //   4: '待提交',
    //   5: '待确认车号',
    //   6: '已确认',
    //   7: '运输中',
    //   8: '已报停',
    //   9: '运输完成',
    // },
  },
  // 运单状态
  wayBillStateMap: {
    // SA: [
    //   { label: '待开始', value: '1,2' },
    //   { label: '进行中', value: '3,4' },
    //   { label: '已完成', value: '5' },
    //   { label: '已关闭', value: '0' },
    // ],
    SA: [
      { label: '待开始', value: 1 },
      { label: '进行中', value: 3 },
      { label: '已完成', value: 5 },
      { label: '已关闭', value: 0 },
    ],
    // SA: [
    //   { label: '待开始', value: 1 },
    //   { label: '进行中', value: 2 },
    //   { label: '已完成', value: 3 },
    //   { label: '已关闭', value: 0 },
    // ],
    // 1,2待开始，3,4进行中，5已完成，0已关闭
    O: {
      1: '待开始',
      2: '待开始',
      3: '进行中',
      4: '进行中',
      5: '已完成',
      0: '已关闭',
    },
  },
  // 车单状态
  carOrderStateMap: {
    A: [
      // { label: '待上报', value: 1 },
      // { label: '待确认', value: 2 },
      { label: '待运输', value: 3 },
      { label: '已到装', value: 5 },
      { label: '已装货', value: 7 },
      { label: '已到卸', value: 8 },
      { label: '运输完成', value: 9 },
      { label: '已核定', value: -1 },
      { label: '货主取消', value: 4 },
      { label: '车队取消', value: 6 },
      // { label: '已结算', value: 12 },
      // { label: '已开票', value: 13 },
      { label: '关闭', value: 0 },

    ],
    O: {
      0: '关闭',
      1: '待上报',
      2: '待确认',
      3: '待运输',
      4: '货主取消',
      5: '已到装',
      6: '车队取消',
      7: '已装货',
      8: '已到卸',
      9: '运输完成',
      '-1': '已核定',
      // 12: '已结算',
      // 13: '已开票',
    },
  },
  qualificationTypeMap: {
    1: '货主企业',
    11: '物流企业',
    12: '信息公司',
    13: '个体运力',
  },
};
