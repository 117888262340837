const transport = [
  {
    meta: {
      code: '530',
      keepAlive: true,
    },
    name: '承运货单',
    path: '/transport/goods',
    component: () => import('@/views/transport/goods'),
  },
  {
    meta: {
      code: '530',
      keepAlive: true,
    },
    name: '承运货单详情',
    path: '/transport/goods/detail',
    component: () => import('@/views/transport/goods/detail'),
  },
  {
    meta: {
      code: '510',
      keepAlive: true,
    },
    name: '运单列表',
    path: '/transport/wayBill',
    component: () => import('@/views/transport/wayBill'),
  },
  {
    meta: {
      code: '510',
      keepAlive: true,
    },
    name: '运单详情',
    path: '/transport/wayBill/detail',
    component: () => import('@/views/transport/wayBill/detail'),
  },
  {
    meta: {
      code: '520',
      keepAlive: true,
    },
    name: '车单列表',
    path: '/transport/vehicleBill',
    component: () => import('@/views/transport/vehicleBill'),
  },
  {
    meta: {
      code: '520',
      keepAlive: true,
    },
    name: '车单详情',
    path: '/transport/vehicleBill/detail',
    component: () => import('@/views/transport/vehicleBill/detail'),
  },
  {
    meta: {
      code: '540',
      // keepAlive: true,
    },
    name: '履约动态',
    path: '/transport/monitor',
    component: () => import('@/views/transport/monitor'),
  },
  {
    meta: {
      code: '540',
      // keepAlive: true,
    },
    name: '车辆查询',
    path: '/transport/map',
    component: () => import('@/views/transport/map'),
  },
];

export default transport;
