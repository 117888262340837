<template>
  <div class="nav-c">
    <!-- <div class="crumb-c">
      <el-breadcrumb separator="/">
        <template v-for="(item, index) in crumbList">
          <template v-if="item.hideInCrumb && currentPath !== item.path" />
          <el-breadcrumb-item v-else-if="item.noCrumbPath || currentPath === item.path" :key="index">{{ item.title }}</el-breadcrumb-item>
          <el-breadcrumb-item v-else :key="index" :to="{ path: item.path, query: item.query}">{{ item.title }}</el-breadcrumb-item>
        </template>
      </el-breadcrumb>
    </div> -->
    <div class="tag-c">
      <el-tag
        v-for="(item, index) in navList"
        :key="index"
        class="tag-nav"
        :class="{'active': item.path === currentPath}"
        :closable="item.path !== '/'"
        @click="goNav(item);"
        @close="removeNavFun(item.path);"
      >
        {{ item.title }}
      </el-tag>
      <div class="tab-line" />
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { pathToName } from '@/assets/js/libs';

export default {
  data() {
    return {
      currentPath: '',
    };
  },
  computed: {
    ...mapState({
      navList() { return this.$store.state.common.navList; },
      menuList() { return this.$store.state.common.menuList; },
      crumbList() { return this.$store.state.common.crumbList; },
    }),
  },
  watch: {
    $route() {
      this.currentPath = this.$route.path;
      this.setCrumbList([]);
      this.getCrumbList(this.menuList);
      this.addNav({
        title: this.$route.name,
        path: this.$route.path,
        query: this.$route.query,
      });
    },
  },
  created() {
    this.setCrumbList([]);
    this.currentPath = this.$route.path;
    this.addNav({
      title: this.$route.name,
      path: this.$route.path,
      query: this.$route.query,
    });
    this.getCrumbList(this.menuList);
  },
  methods: {
    ...mapMutations({
      addNav: 'common/addNav',
      removeNav: 'common/removeNav',
      setCrumbList: 'common/setCrumbList',
      pushCrumb: 'common/pushCrumb',
      removeAlive: 'common/removeAlive',
    }),
    getCrumbList(data) {
      const { subPath } = this.$route.query;
      let { path } = this.$route;
      if (subPath) {
        path = `${subPath}${path}`;
      }
      data.some((item) => {
        if (path === '/') {
          this.pushCrumb(item);
          return true;
        }
        if (path.includes(item.path) && (item.path !== '/')) {
          this.pushCrumb(item);
          if (item.children && item.children.length > 0) {
            this.getCrumbList(item.children);
          }
          return true;
        }
      });
    },
    goNav(path) {
      this.$router.push({
        path: path.path,
        query: path.query,
      });
      // this.$router.push({
      //   path: path.path,
      //   query: Object.assign(path.query || {}, {
      //     removeAlive: true,
      //   }),
      // });
    },
    removeNavFun(path) {
      const list = this.navList;
      if (path === this.currentPath) {
        list.some((val, index) => {
          if (val.path === path) {
            this.$router.push({
              path: list[index - 1].path,
              query: list[index - 1].query,
            });
            return true;
          }
        });
      }
      this.removeAlive(pathToName(path));
      this.removeNav(path);
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-c {
  .crumb-c {
    height: 46px;
    padding-top: 12px;
    padding-left: 20px;
  }
  .tag-c {
    margin-top: 12px;
    height: 36px;
    padding-left: 12px;
    display: flex;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;
    .tab-line {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: #EBEEF5;
      z-index: -1;
    }
  }
}
</style>
