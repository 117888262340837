import router from './router';
import store from './store';
import { Message } from 'element-ui';
import { getToken } from '@/utils/auth';
import { pathToName } from '@/assets/js/libs';

const whiteList = ['/login', '/forget', '/404'];

router.beforeEach((to, from, next) => {
  if (to.query.removeAlive) {
    store.commit('common/removeAlive', pathToName(to.path));
  }
  // 有token 登陆状态
  if (getToken()) {
    document.title = to.name ? `AUV业务平台 | ${to.name}` : 'AUV业务平台';
    if (to.path === '/login') {
      next({ path: '/' });
    } else if (to.path === '/404') {
      next();
    } else {
      // 如果没有菜单列表 则重新调取userInfo接口pathPermissions
      if (!store.state.common.pathPermissions.length) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('common/GetInfo').then((res) => {
          // 暂时无接口
          // store.dispatch('GenerateRoutes').then(accessRoutes => {
          //   console.log(accessRoutes, 'accessRoutessss');
          //   router.addRoutes(accessRoutes); // 动态添加可访问路由表
          //   next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
          // });
          next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
        }).catch(err => {
          store.dispatch('common/FedLogOut').then(() => {
            Message.error(err);
            // next(`/login?redirect=${to.fullPath}`);
            next(`/login?redirect=${encodeURIComponent(to.fullPath)}`);
          });
        });
      } else {
        // 跳转path重定向到404逻辑 菜单code不存在并且不是白名单内
        if (!store.state.common.pathPermissions.includes(to.meta.code) && whiteList.indexOf(to.path) === -1) {
          next({
            path: '/404',
          });
        } else {
          next();
        }
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      // next(`/login?redirect=${encodeURIComponent(to.fullPath)}`); // 否则全部重定向到登录页
      next('/login'); // 否则全部重定向到登录页
    }
  }
});

router.afterEach((to, from) => {
  if (to.meta.keepAlive) {
    store.commit('common/addAlive', pathToName(to.path));
  }
});
