<template>
  <div class="g-table-c g-table">
    <div v-if="tableBtns.length" class="g-table-btn">
      <el-button
        v-for="(btn, index) in tableBtns"
        :key="index"
        :type="btn.type || 'primary'"
        :plain="btn.plain || false"
        :disabled="btn.disabled"
        :icon="btn.icon"
        @click="btn.evt"
      >
        {{ btn.label }}
      </el-button>
    </div>
    <template>
      <slot name="headOther" />
    </template>
    <div class="table">
      <el-table
        v-loading="loading"
        :data="tableData"
        v-bind="bindProps()"
        :border="border"
        :highlight-current-row="showOneSelectionChange"
        @row-click="handleCurrentChange"
        @selection-change="handleSelectionChange"
        @sort-change="changeSort"
      >
        <el-table-column v-if="showOneSelectionChange" label width="35">
          <template slot-scope="scope">
            <el-radio v-model="oneSelectRadioId" :label="scope.row[radioOneSelectionProp]">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column
          v-if="showSelectionChange"
          type="selection"
          width="55"
        />
        <el-table-column
          v-for="(col, index) in tableCols"
          :key="index"
          :prop="col.prop"
          :label="col.label"
          :align="col.align||'left'"
          :min-width="col.width|| '120'"
          show-overflow-tooltip
          :fixed="col.fixed || false"
          :sortable="col.sortable"
        >
          <template slot="header">
            <!-- <slot v-if="col.appendHeader" name="appendHeader" :label="col.label" :col="col" /> -->
            <slot v-if="col.headerCustomize" name="headerCustomize" :label="col.label" :col="col" />
            <!-- 定制 多个页面涉及日期筛选 -->
            <!-- <TimeSelect v-if="col.headTimeSelect" @selectTime="selectTime" /> -->
            <!-- {{ col.label }} -->
            <div v-if="col.headTimeSelect||col.needShowLabel">{{ col.label }}</div>
            <TimeSelect v-if="col.headTimeSelect" @selectTime="selectTime" />
          </template>
          <template slot-scope="{ row }">
            <!-- <template #default="{ row }"> -->
            <div v-if="'operation' === col.type" class="operation-row flex-c">
              <span v-if="col.rowBtns ? !col.rowBtns.length : !col.btns.length">-</span>
              <div
                v-for="(btn, rindex) in col.btns || col.rowBtns"
                :key="rindex"
                :class="['cursor', btn.className||'main-c']"
                type="text"
                @click.stop="btn.evt(row)"
              >
                <span>{{ btn.label }}</span>
                <!-- 操作类 -->
              </div>
            </div>
            <!-- 自定义类 可在父组件中自定义 -->
            <slot v-else-if="'customize' === col.type" :row="row" :col="col" />
            <!-- 时间格式类 -->
            <div v-else-if="'filterTime' === col.type" class="ellipsis">
              {{ row[col.prop]| parseTime(col.parseTimeType||'YYYY-MM-DD HH:mm:ss') }}
            </div>
            <!-- 对象value时间格式类获取 -->
            <div v-else-if="'filterTimeObjShow' === col.type" class="ellipsis">
              {{ getObjValueForTime(col.objShow,row,col.parseTimeType) ||'-' }}
            </div>
            <!-- 转化对象类 -->
            <div v-else-if="'transition' === col.type" class="ellipsis">
              {{ col.rulesObj[row[col.prop]]||'-' }}
            </div>
            <!-- 转化数组类 -->
            <!-- <div v-else-if="'transitionArray' === col.type" class="ellipsis">
              {{ transitionArrayToObject(col.rulesArray,row[col.prop],col.keyValueParams) ||'-' }}
            </div> -->
            <!-- 对象value获取 -->
            <div v-else-if="'objShow' === col.type" class="ellipsis">
              {{ getObjValue(col.objShow,row) ||'-' }}{{ col.unit||'' }}
            </div>
            <!-- 转化数字 缩小(默认)/放大 1000 -->
            <div v-else-if="'numberTransition' === col.type" class="ellipsis">
              {{ bigNumber(row[col.prop], col.multiple||1000,col.multipleType||'div') || "-" }}
            </div>
            <!-- 可点击跳转类 -->
            <div
              v-else-if="'jump' === col.type"
              class="ellipsis"
              :class="col.className||'main-c-cursor'"
              @click="col.evt(row)"
            >
              {{ row[col.prop] || '-' }}
            </div>
            <!-- 新增人员信息模块组件 -->
            <div
              v-else-if="'userInfoCard' === col.type"
            >
              <!-- :user-info="row" -->
              <UserInfoCard size-type="table" :user-info="row[col.userInfo]||row" :user-type="col.userType||row.source||1" :need-change-user-info="col.needChangeUserInfo" :need-match="col.needMatch" />
            </div>
            <!-- 普通类 仅展示文字 -->
            <div v-else :class="['ellipsis',col.className]">{{ row[col.prop] || '-' }}<span v-if="col.unitObj">{{ col.unitObj[row[col.unitProp]]||'' }}</span>{{ col.unit||'' }}</div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-bottom">
      <div v-show="showSelectionChange&&multipleSelection.length" class="select-num">已选{{ multipleSelection.length }}条数据</div>
      <div
        v-if="showPagination"
        class="g-pagination-c"
      >
        <el-pagination
          layout="sizes, prev, pager, next, jumper, total"
          :total="pagination.total"
          :page-size="pagination.size"
          :current-page="pagination.page"
          @current-change="pageChange"
          @size-change="sizeChange"
        />
      </div>
    </div>

  </div>
</template>

<script>
import TimeSelect from '@/components/SearchForm/timeSelect';
import { parseTime } from '@/utils';
// import { convertArrayToObject } from '@/utils/auv';
import Big from 'big.js';
export default {
  components: { TimeSelect },
  filters: {
    parseTime,
  },
  props: {
    // 是否展示操作按钮
    // showBtn: {
    //   type: Boolean,
    //   default() {
    //     return false;
    //   },
    // },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 操作按钮集合
    tableBtns: {
      type: Array,
      default() {
        return [];
      },
    },
    // 表格列数据
    // label：列名
    // prop：对应行数据的key
    // sortType：排序类型【all-升序+降序 asc：升序 desc：降序】
    // sortProp：排序接口对应的排序字段 排序时必传（获取表格数据api接口参数中解构排序字段时必须设置默认值空字符串）
    tableCols: {
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    // table高度
    tableHeight: {
      type: [String, Number],
      default() {
        return 'auto';
      },
    },
    // 是否是以最大高度形式展示
    maxHeight: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 是否展示border
    border: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 单选是否可勾选
    showOneSelectionChange: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 单选时候匹配的字段
    radioOneSelectionProp: {
      type: String,
      default() {
        return 'id';
      },
    },
    // 是否可勾选
    showSelectionChange: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 勾选项
    multipleSelection: {
      type: Array,
      default() {
        return [];
      },
    },
    // 是否分页
    showPagination: {
      type: Boolean,
      default() {
        return true;
      },
    },
    pagination: {
      type: Object,
      default() {
        return {
          total: 50,
          size: 10,
          page: 1,
        };
      },
    },
  },
  data() {
    return {
      oneSelectRadioId: null,
    };
  },
  created() {
  },
  methods: {
    // 绑定属性
    bindProps() {
      if (this.tableHeight !== 'auto') {
        if (this.maxHeight) {
          return {
            maxHeight: this.tableHeight,
          };
        } else {
          return {
            height: this.tableHeight,
          };
        }
      } else return {};
    },
    // rowClassName(row, rowIndex) {
    //   if (row.row.status === -1) {
    //     return 'grey-row';
    //   } else if (row.row.status === 6) {
    //     return 'blue-row';
    //   }
    // },
    // setRowStyle(row, column, rowIndex, columnIndex) {
    //   // if (row.id === 1) {
    //   //   return "color:green";
    //   // } else {
    //   //   return "color:red";
    //   // }
    // },
    // cellClsNm({ column }) {
    //   // if (column.property === 'editable') {
    //   //   return 'td-edit-cls'
    //   // }
    // },
    // 单行选中
    handleCurrentChange(val) {
      if (this.showOneSelectionChange) {
        this.oneSelectRadioId = val[this.radioOneSelectionProp];
        this.$emit('update:oneSelection', [val]);
      }
    },
    // 多行选中
    handleSelectionChange(val) {
      // 两种方式 1方法传参 2直接更新父级数据
      // this.$emit('handleSelectionChange', val);
      this.$emit('update:multipleSelection', val);
    },
    // 自定义排序
    changeSort(val) {
      this.$emit('changeSort', val);
    },
    sizeChange(size) {
      this.$emit('sizeChange', size);
    },
    pageChange(page) {
      this.$emit('pageChange', page);
    },
    // 定制表头时间筛选
    selectTime(time) {
      this.$emit('selectTime', time);
    },
    // 获取两层对象结构值
    getObjValue(obj, data) {
      return data[obj.keyPar] ? data[obj.keyPar][obj.keyChild] : '-';
    },
    // parseTime(col.parseTimeType||'YYYY-MM-DD HH:mm:ss')
    getObjValueForTime(obj, data, type) {
      return data[obj.keyPar] ? parseTime(data[obj.keyPar][obj.keyChild], type || 'YYYY-MM-DD HH:mm:ss') : '-';
    },
    // 数组转换为对象
    // transitionArrayToObject(rulesArray, data, keyValueParams = { key: 'key', value: 'value' }) {
    //   if (rulesArray.length && data) {
    //     const convertNewObject =
    //     convertArrayToObject(rulesArray, keyValueParams.key, keyValueParams.value);
    //     return convertNewObject[data] || '-';
    //   } else {
    //     return '-';
    //   }
    // },

    bigNumber(num, multiple, type) {
      if (num === '' || num === 'undefined' || num === undefined || num === null) {
        return '-';
      } else {
        // 乘以
        if (type === 'times') {
          return Math.round(new Big(+num).times(multiple) * 100) / 100;
        } else {
          // 除以
          return Math.round(new Big(+num).div(multiple) * 100) / 100;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.table-bottom{
  position: relative;
  .select-num{
    font-size: 14px;
    color: #4F5769;
    position: absolute;
    left: 0;
    top: 25px;
  }
}
::v-deep {
  .grey-row {
    color: #c0c4cc;
    .main-c ,td{
      color: #c0c4cc;
    }
  }
  .blue-row {
    color: #2773FF;
     td {
      opacity: 0.6;
      color: #2773FF;
      &:last-child{
        opacity: 1;
      }
    }
  }
  .is-sortable{
    .cell{
      display: flex!important;
      align-items: center;
    }
    .caret-wrapper{
     .ascending{
      top: 7px;
     }
     .descending{
      bottom: 5px;
     }
    }
  }
  .table-column-wrap{
    margin-top: -12px;
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 24px;
    line-height: 18px;
    justify-content: center;
    div{
      font-size: 14px;
    }
  }
  .action-wrap{
    display: flex;
    >div{
      margin-right: 5px;
      cursor: pointer;
    }
  }
}
.nodata {
  line-height: 60px;
  text-align: center;
  color: #909399;
}
</style>
<style lang="scss">
.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>
