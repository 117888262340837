import Vue from 'vue';
// 公共搜索
import Search from '@/components/SearchForm/searchForm';
Vue.component('search-form', Search);
import SearchItem from '@/components/SearchForm/item';
Vue.component('search-item', SearchItem);
import Table from '@/components/Table/index';
Vue.component('Table', Table);
import Upload from '@/components/multiUpload';
Vue.component('d5c-upload', Upload);
// import FileImg from '@/components/FileImg';
// Vue.component('file-img', FileImg);
import ImageUpload from '@/components/ImageUpload';
Vue.component('ImageUpload', ImageUpload);
import UserInfoCard from '@/components/userInfoCard';
Vue.component('UserInfoCard', UserInfoCard);

// 公共分页组件
import Pagination from '@/components/Pagination';
Vue.component('Pagination', Pagination);

// import VueFullscreen from 'vue-fullscreen';
// Vue.use(VueFullscreen);

import QuerySelect from '@/components/QuerySelect/QuerySelect';
Vue.component('query-select', QuerySelect);
// import SelectBox from '@/components/SelectBox/SelectBox';
// Vue.component('select-box', SelectBox);
import TextToolTip from '@/components/TextToolTip';
Vue.component('TextToolTip', TextToolTip);
