// 数据字典
import { getDictionaryList } from '@/api/dictionary';
import _ from 'lodash';
const namespaced = true;
const state = {
  dics: {},
  stateArrayMap: [
    { label: '未生效', value: 1 },
    { label: '生效', value: 2 },
    { label: '停用', value: 3 },
  ],
  stateObjMap: {
    1: '未生效',
    2: '生效',
    3: '停用',
  },
  // 载重
  loadTypeArrayMap: [
    { label: '重型', value: '1' },
    { label: '中型', value: '2' },
    { label: '轻型', value: '3' },
  ],
  loadTypeObjMap: {
    1: '重型',
    2: '中型',
    3: '轻型',
  },
  // 牵引挂
  tractionHangerArrayMap: [
    { label: '半挂', value: 1 },
    { label: '全挂', value: 2 },
    { label: '一体式', value: 3 },
  ],
  tractionHangerObjMap: {
    1: '半挂',
    2: '全挂',
    3: '一体式',
  },
};
const getters = {
  dics: (state) => state.dics,
};
const mutations = {
  setDics(state, { key, data }) {
    state.dics[key] = data;
  },
  // setConvertArray(state, { key, data }) {
  //   state.dics[key] = data;
  // },
  // convertArrayToObject
};
const actions = {
  async getDics({ commit }, params) {
    const d = await getDictionaryList(params);
    const data = {};
    d.forEach((list) => {
      const key = list.code;
      const O = {};
      const A = list.dictValueVOList.map((item) => {
        O[item.key] = item.value;
        return { label: item.value, value: item.key };
      });
      data[key] = { A, O };
      commit('setDics', { key, data: { A, O }});
    });
    return data;
  },
  async get({ commit, state, dispatch }, types) {
    let keyArray = [];
    if (_.isArray(types)) {
      keyArray = types;
    } else {
      keyArray = [types];
    }
    const filterArr = keyArray.filter((item) => {
      return !state.dics[item];
    });
    if (filterArr.length > 0) {
      await dispatch('getDics', { dictCodeList: filterArr });
    }
    return _.pick(state.dics, keyArray);
  },
};
export default { namespaced, state, getters, mutations, actions };
