<template>
  <el-pagination
    layout="sizes, prev, pager, next,jumper,total"
    v-bind="$attrs"
    :background="true"
    :hide-on-single-page="false"
    @size-change="onSizeChange"
    v-on="$listeners"
  />
</template>
<script>
export default {
  name: 'Pagination',
  props: {},
  methods: {
    onSizeChange(index) {
      this.$emit('update:pageSize', index);
      this.$emit('current-change', 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-pagination {
  margin-top: 20px;
  ::v-deep {
    .btn-prev,
    .btn-next {
      padding: 0 4px;
    }
    .el-pagination__total {
      margin-right: 0;
      margin-left: 10px;
    }
     .el-input__inner{
      height: 28px;
      line-height: 28px;
    }
  }
  display: flex;
  justify-content: flex-end;
  padding-bottom: 20px;
  align-items: center;
}
</style>
