<template>
  <el-popover v-model="visible" placement="bottom" width="300" trigger="manual">
    <el-date-picker
      v-model="value"
      type="daterange"
      align="right"
      unlink-panels
      value-format="timestamp"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions"
    />
    <div style="margin-top:5px;float:right">
      <el-button size="mini" @click="resetData">重置</el-button>
      <el-button type="primary" size="mini" style="margin-top:5px" @click="confirm">确定</el-button>
    </div>
    <div slot="reference" v-click-outside="closeOver" class="append-select-time" @click.stop="popClick">
      <i v-if="!visible" class="iconfont icon-filter-line" style="color:#C0C4CC" />
      <i v-else class="iconfont icon-filter-2-fill main-c" />
    </div>
  </el-popover>
</template>

<script>
export default {
  directives: {
    clickOutside: {
      bind(el, binding, vnode) {
        function clickHandler(e) {
          // 这里判断点击的元素是否是本身，是本身，则返回
          if (el.contains(e.target)) {
            return false;
          }
          // 判断指令中是否绑定了函数
          if (binding.expression) {
            // 如果绑定了函数 则调用那个函数，此处binding.value就是handleClose方法
            binding.value(e);
          }
        }
        // 给当前元素绑定个私有变量，方便在unbind中可以解除事件监听
        el.__vueClickOutside__ = clickHandler;
        document.addEventListener('click', clickHandler);
      },
      update() {},
      unbind(el, binding) {
        // 解除事件监听
        document.removeEventListener('click', el.__vueClickOutside__);
        delete el.__vueClickOutside__;
      },
    },
  },
  data() {
    return {
      visible: false,
      value: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          },
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          },
        }],
      },
    };
  },
  methods: {
    popClick() {
      this.visible = !this.visible;
    },
    closeOver() {
      this.visible = false;
    },
    resetData() {
      this.value = '';
      this.visible = false;
      this.$emit('selectTime', null);
    },
    confirm() {
      this.visible = false;
      this.$emit('selectTime', this.value);
    },
  },
};
</script>
