// 去掉输入框中的空格
export function trim(str) {
  if (str !== '') {
    str = str.replace(/^\s+|\s+$/g, '');
  }
  return str;
}
/**
 * @description 格式化时间
 * @param time
 * @param cFormat
 * @returns {string|null}
 */
import dayjs from 'dayjs';
export function parseTime(time, cFormat = 'YYYY-MM-DD HH:mm') {
  if (!time) return '--';
  return dayjs(+time).format(cFormat);
}
/**
 * @description 加减乘除
 * @param num1
 * @param num2
 * @param multiple 倍数
 * @param type 加减乘除 加 plus  减 minus  乘 times 除 div
 * @param multipleType 乘 times 除 div
 * @returns {Number}
 */
import Big from 'big.js';
export function numberCalculation(num1, num2, multiple = 1, type = 'plus', multipleType = 'div') {
  if (num1 === '' || num1 === 'undefined' || num1 === undefined || num1 === null || num2 === '' || num2 === 'undefined' || num2 === undefined || num2 === null) {
    return '-';
  } else {
    // 使用 parseFloat() 函数将字符串转换为浮点数
    const numA = parseFloat(num1);
    const numB = parseFloat(num2);
    // plus 加   //minus减   //times乘   //div除
    return parseFloat(new Big(numA)[type](numB)[multipleType](multiple));
  }
}
/**
 * @description 乘除
 * @param num
 * @param multiple 倍数
 * @param multipleType 乘 times 除 div
 * @returns {Number}
 */
export function parseNumber(num, multiple = 100, multipleType = 'div') {
  if (num === '' || num === 'undefined' || num === undefined || num === null) {
    return '-';
  } else {
    // 使用 parseFloat() 函数将字符串转换为浮点数
    const numA = parseFloat(num);
    // times乘   //div除
    return parseFloat(new Big(numA)[multipleType](multiple));
  }
}
/**
 * 校验数据类型
 */
export const type = (() => {
  const toString = Object.prototype.toString;
  const dataType = ['Number', 'String', 'Boolean', 'Array', 'Object', 'Symbol', 'Undefined', 'Null', 'Function', 'Date', 'RegExp', 'Error'];
  const type = {};
  for (const typeName of dataType) {
    const fnName = `is${typeName}`;
    !type[fnName] &&
      (type[fnName] = (value) => {
        const checkResult = `[object ${typeName}]`;
        return toString.call(value) === checkResult;
      });
  }
  return type;
})();

/**
 * 删除对象里面的属性值是空或者数组
 */

export function delObjectKey(object) {
  if (!object) {
    return;
  }
  for (const o in object) {
    if ((!object[o] && object[o] !== 0) || (object[o] && object[o].length === 0)) {
      delete object[o];
    }
  }
  return object;
}

export function debounce(func, wait) {
  let timer = null;
  return () => {
    const context = this;
    const args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      clearTimeout(timer);
      timer = null;
      func.apply(context, args);
    }, wait);
  };
}

/**
 * @description 前端按json数据导出
 * @param data list
 * @param header 表头
 */
// 引入前端导出插件
import fileSaver from 'file-saver';
import xlsx from 'xlsx';
export function exportByJson(exportData = [{}], headerList = [], name = 'sheet.xlsx') {
  const header = [headerList];
  const headerWs = xlsx.utils.aoa_to_sheet(header);
  const ws = xlsx.utils.sheet_add_json(headerWs, exportData, { skipHeader: true, origin: 'A2' });
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, ws, 'sheetName');
  const out = xlsx.write(wb, {
    bookType: 'xlsx',
    bookSST: true,
    type: 'array',
  });
  try {
    fileSaver.saveAs(
      new Blob([out], {
        type: 'application/octet-stream',
      }),
      name,
    );
  } catch (e) {
    // 错误处理方式
  }
  return out;
}

/**
 * 表单对象赋值:
 * 对目标对象存在且源对象同样存在的属性，全部覆盖；
 * 目标对象不存在但是源对象存在的属性， 全部丢弃；
 * 目标对象存在但是源对象不存在的属性，如果是字符串赋值为空串，其余类型赋值为undefined
 */
export function recover(target, source) {
  if (target === undefined || target === null) {
    throw new TypeError('Cannot convert first argument to object');
  }
  const to = Object(target);
  if (source === undefined || source === null) {
    return to;
  }
  const keysArray = Object.keys(Object(target));
  for (
    let nextIndex = 0, len = keysArray.length;
    nextIndex < len;
    nextIndex++
  ) {
    const nextKey = keysArray[nextIndex];
    const desc = Object.getOwnPropertyDescriptor(target, nextKey);
    if (desc !== undefined && desc.enumerable) {
      if (Object.prototype.hasOwnProperty.call(to, nextKey)) {
        if (to[nextKey] instanceof Array) {
          to[nextKey] = source[nextKey];
        } else if (to[nextKey] instanceof Object) {
          recover(to[nextKey], source[nextKey]);
        } else if (source[nextKey] !== undefined) {
          to[nextKey] = source[nextKey];
        } else if (typeof to[nextKey] === 'string') {
          to[nextKey] = '';
        } else {
          to[nextKey] = undefined;
        }
      }
    }
  }
  return to;
}

import qs from 'qs';
export function parseParams(params) {
  return qs.stringify(params, {
    allowDots: true,
  });
}
