<template>
  <el-dialog
    :title="drawerTitle"
    :visible="drawerVisible"
    width="500px"
    @close="close"
  >
    <div class="content">
      <el-form ref="form" :model="drawerForm" label-width="130px" :rules="rules" :disabled="formDisabled" label-position="right">
        <el-form-item label="用户姓名" prop="name">
          <el-input v-model="drawerForm.name" placeholder="请输入" clearable />
        </el-form-item>
        <el-form-item label="用户手机号" prop="phone">
          <el-input v-model="drawerForm.phone" placeholder="请输入" clearable />
        </el-form-item>
        <!-- 默认启用，不可更改 -->
        <el-form-item label="帐号状态" prop="state">
          <el-select v-model="drawerForm.state" class="g-inp" clearable placeholder="请选择" :disabled="stateDisabled">
            <el-option v-for="item in allAccountStatus" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="所属角色" prop="postCode">
          <el-select v-model="drawerForm.postCode" class="g-inp" clearable placeholder="请选择" filterable>
            <el-option v-for="item in allRole" :key="item.postCode" :label="item.postName" :value="item.postCode" />
          </el-select>
        </el-form-item>
        <el-form-item label="所属部门" prop="departId">
          <el-select v-model="drawerForm.departId" class="g-inp" clearable placeholder="请选择" filterable>
            <el-option v-for="item in allDepartment" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input v-model="drawerForm.remark" type="textarea" rows="1" placeholder="请输入" clearable />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button v-if="drawerTypeDesc==='add'||drawerTypeDesc==='edit'" type="primary" @click="submit">提交</el-button>
      <el-button v-if="drawerTypeDesc==='detail'" type="primary" @click="drawerTypeDesc = 'edit'">编辑</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { validateName, validateTel } from '@/utils/validateRule';
import { getSysTemDepartList, getSysTemPostList, addSysTemUser, updateSysTemUser, getSysTemUserInfo } from '@/api/systemUser';
export default {
  props: {
    // 来源 此页面两个地方共用  头部公用是不需要删除功能
    form: {
      type: String,
      default() {
        return 'normal';
      },
    },
    // 详情类型 add 新增 edit编辑 detail详情
    drawerType: {
      type: String,
      default() {
        return 'add';
      },
    },
    drawerVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    drawerId: {
      type: [String, Number],
      default() {
        return '';
      },
    },
    departMent: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      drawerTypeDesc: '',
      drawerTitleObj: {
        'add': '新增用户',
        'edit': '编辑用户',
        'detail': '查看用户',
      },
      drawerForm: {
        state: 1, // 默认启用，不可更改
      },
      rules: {
        name: [
          { required: true, message: '请输入用户姓名', trigger: 'blur' },
          { validator: validateName('请输入正确的姓名'), trigger: 'change' },
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validateTel, trigger: 'change' },
        ],
        state: [
          { required: true, message: '请选择账号状态', trigger: 'change' },
        ],
        postCode: [
          { required: true, message: '请选择所属角色', trigger: 'change' },
        ],
        departId: [
          { required: true, message: '请选择所属部门', trigger: 'change' },
        ],
      },
      allAccountStatus: [],
      allRole: [],
      allDepartment: [],
    };
  },

  computed: {
    formDisabled() {
      return this.drawerTypeDesc === 'detail';
    },
    drawerTitle() {
      return this.drawerTitleObj[this.drawerTypeDesc];
    },
    stateDisabled() {
      return this.drawerTypeDesc === 'add' || this.form !== 'normal';
    },
  },

  watch: {
    drawerVisible: {
      handler: function(visible) {
        if (visible) {
          this.drawerTypeDesc = this.drawerType;
          this.getAccountStatus();
          this.getRole();
          this.getDepartment();
          if (this.drawerTypeDesc === 'detail' || this.drawerTypeDesc === 'edit') {
            this.getDetail();
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    // 调度列表
    getAccountStatus() {
      this.allAccountStatus = [
        { name: '启用', id: 1 },
        { name: '禁用', id: 2 },
      ];
    },
    // 角色列表
    getRole() {
      getSysTemPostList({ }).then(res => {
        this.allRole = res || [];
      }).finally(() => {
        this.loading = false;
      });
      // this.allRole = [
      //   { name: '货客服', id: 1, postCode: 'PT004' },
      //   { name: '车客服', id: 2, postCode: 'PT005' },
      //   { name: '运营', id: 3, postCode: 'PT006' },
      //   { name: '财务', id: 4, postCode: 'PT007' },
      // ];
    },
    // 部门列表
    getDepartment() {
      // this.allDepartment = [this.departMent];
      // [
      //   { name: '五城智慧物流', id: 1 },
      // ];
      getSysTemDepartList({}).then((res) => {
        const newRes = res.filter((d) => d.parentId >= 0);
        this.allDepartment = newRes;
      });
    },
    getDetail() {
      getSysTemUserInfo({ userId: this.drawerId }).then(res => {
        res.departId = res.departList[0]?.id || null;
        res.postCode = res.postList[0]?.postCode || null;
        this.drawerForm = res || {};
        // 部门模拟回显
        // this.drawerForm.departId = 1;
      });
    },
    submit() {
      // type 获取type 分别对应api提交
      this.$refs.form.validate((valid) => {
        if (valid) {
          const drawerForm = JSON.parse(JSON.stringify(this.drawerForm));
          const addParams = {
            user: {
              ...drawerForm,
            },
            account: {
              account: drawerForm.phone,
              state: drawerForm.state,
            },
          };
          delete addParams.user.state;
          delete addParams.user.departList;
          delete addParams.user.postList;
          const params = addParams;
          if (this.drawerTypeDesc === 'add') {
            addSysTemUser(params).then(res => {
              if (res) {
                this.$baseMessage('新增成功!', 'success');
                this.$emit('drawerSubmit');
                this.close();
              }
            });
          } else {
            updateSysTemUser(params).then(res => {
              if (res) {
                this.$baseMessage('编辑成功!', 'success');
                this.$emit('drawerSubmit');
                this.close();
              }
            });
          }
        }
      });
    },
    close() {
      this.drawerForm = {
        state: 1,
      };
      this.$refs.form.resetFields();
      this.$emit('update:drawerVisible', false);
    },
  },
};

</script>
<style lang='scss' scoped>
</style>
