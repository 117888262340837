import request from '@/plugins/axios';
const prefix = '/auv';
/* ----------登录------------ */
export const login = (data) => {
  return request.post(`${prefix}/user/login`, data).then((res) => {
    return res;
  });
};
/* ----------用户详情------------ */
export const getInfo = (data) => {
  return request.post(`${prefix}/user/info`, data).then((res) => {
    return res;
  });
};

/* ----------发送验证码------------ */
export const sendCode = (data) => {
  return request.post(`${prefix}/auv/user/message/send`, data).then((res) => {
    return res;
  });
};
/* ----------验证验证码----------- */
export const checkCode = (data) => {
  return request.post(`${prefix}/user/message/check`, data, { showLoading: true }).then((res) => {
    return res;
  });
};
/* ----------修改密码------------ */
export const changePassword = (data) => {
  return request.post(`${prefix}/user/account/passwd/update`, data, { showLoading: true }).then((res) => {
    return res;
  });
};
/* ----------修改密码------------ */
export const changePasswordMsg = (data) => {
  return request.post(`${prefix}/user/account/msg/passwd/update`, data, { showLoading: true }).then((res) => {
    return res;
  });
};

export const upload = (data) => {
  return request.post(`${prefix}/configure/file/upload`, data).then((res) => {
    return res;
  });
};

/* ----------大屏token------------ */
export const dataToken = (data, options = {}) => {
  // 入参处理
  return request.post('/paas/data/token?md=001&cmd=001', data, options).then((res) => {
    //  回参处理
    return res;
  });
};
