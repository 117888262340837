<template>
  <div class="header-container-wrap">
    <div class="logo-img">
      <img src="@/assets/images/common/head-auv-logo.svg">
    </div>
    <div class="right-user-wrap">
      <div class="message-wrap" @click="$router.push('/msgCenter')">
        <i class="iconfont icon-notification-line" />
        <!-- <span class="num">{{ msgNum }}</span> -->
      </div>
      <div class="line" />
      <el-dropdown @command="handleCommand" @visible-change="dropdownChange">
        <span class="el-dropdown-link">
          <img src="@/assets/images/common/user_icon.svg">
          <span class="user-name">{{ userName }}</span>
          <span class="post-name">{{ roleCodeTypeMap[postName] }}</span>
          <i v-if="!caretFlag" class="el-icon-caret-bottom" />
          <i v-else class="el-icon-caret-top" />
        </span>
        <el-dropdown-menu slot="dropdown" style="padding:5px 0">
          <el-dropdown-item command="account" style="padding:0 17px">
            <i class="iconfont icon-user-fill" />
            用户信息
          </el-dropdown-item>
          <el-dropdown-item command="logout" divided style="padding:0 17px">
            <i class="iconfont icon-tuichu" />
            退出登录
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <Drawer :drawer-visible.sync="drawerVisible" drawer-type="detail" :drawer-id="id" />
  </div>
</template>

<script>
import Drawer from '@/views/system/user/drawer';
import { roleCodeTypeMap } from '@/config/constant';
export default {
  components: { Drawer },
  data() {
    return {
      roleCodeTypeMap,
      caretFlag: false,
      drawerVisible: false,
      id: this.$store.state.common.AUV_USER_ID,
      msgNum: null,
    };
  },
  computed: {
    userName() {
      return this.$store.state.common.userInfo.username;
    },
    postName() {
      return this.$store.state.common.userInfo.post;
    },
  },
  methods: {
    dropdownChange(e) {
      this.caretFlag = e;
    },
    handleCommand(command) {
      switch (command) {
        case 'account':
          this.account();
          break;
        case 'logout':
          this.logout();
          break;
      }
    },
    account() {
      this.drawerVisible = true;
    },
    logout() {
      this.$confirm('您确定要退出吗?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$store.dispatch('common/FedLogOut').then((res) => {
          this.$router.push({
            path: '/login',
          });
        });
      }).catch(() => {
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header-container-wrap {
  width: 100%;
  height: 48px;
  background-color: #1B263D;
  position: relative;
  .message-wrap{
    position: relative;
    .num{
      position: absolute;
      font-weight: normal;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 20px;
      min-height: 20px;
      background: red;
      color: #fff;
      border-radius: 20px;
      top: -10px;
      left: 10px;
    }
  }
  .line {
    width: 1px;
    height: 14px;
    background-color: #8A93A4;
    margin: 0 16px;
  }
  .logo-img {
    width: 260px;
    height: 48px;
    display: flex;
    align-items: center;
    img {
      width: 231px;
      height:31px;
      margin-left: 28px;
    }
  }
  .right-user-wrap {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 0 20px;
    .icon-notification-line{
      color: #fff;
      cursor: pointer;
      font-size: 18px;
    }
    .el-dropdown-link {
      display: flex;
      align-items: center;
      cursor: pointer;
      img{
        width: 30px;
        border-radius: 50%;
        background-color: #fff;
      }
      .user-name {
        font-size: 14px;
        color: #fff;
        margin:0 12px;
      }
      .post-name{
        font-size: 13px;
        color: #fff;
        margin-right: 12px;
      }
    }
  }
}
::v-deep{
  .el-dropdown-menu__item--divided:before {
    content: '';
    height: 6px;
    display: block;
    margin: 0 -17px;
    background-color: #fff;
  }
}
</style>
