import Vue from 'vue';
import { messageDuration } from '@/config/settings';
import ElementUI, { Message, MessageBox, Loading } from 'element-ui';
// import '@/assets/scss/element-variables.scss';
import 'element-ui/lib/theme-chalk/index.css';

ElementUI.TableColumn.props.showOverflowTooltip = { type: Boolean, default: true };
// medium small mini
Vue.use(ElementUI, {
  size: 'medium',
});
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = Message;
/* 全局Message */
Vue.prototype.$baseMessage = (message, type = 'info') => {
  // type: success/warning/info/error
  Message({
    offset: 60,
    showClose: true,
    message: message,
    type: type,
    dangerouslyUseHTMLString: true,
    duration: messageDuration,
  });
};
Vue.prototype.$baseLoading = (text, index = 1, callback) => {
  let loading;
  // eslint-disable-next-line prefer-const
  loading = Loading.service({
    lock: true,
    text: text || '正在加载中...',
    spinner: 'loading-type-' + index,
    background: 'hsla(0,0%,100%,.8)',
  });
  if (callback) {
    callback(loading);
  } else {
    setTimeout(() => {
      loading.close();
    }, messageDuration);
  }
};
