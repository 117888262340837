
// 角色编码
export const roleCodeTypeMap = {
  PT001: '货主业务员',
  PT002: '车队业务员',
  PT003: '车队调度',
  PT004: '货客服', // AUV前线(货客服)
  PT005: '车客服', // AUV小五(车客服)
  PT007: 'AUV管理员', // AUV管理员
};
// 客服类型
// export const customerTypeMap = {
//   8: '货客服',
//   9: '车客服',
// };
// 帐号状态  // 状态 1:有效 0:禁用
// export const accountStatusMap = {
//   1: '启用',
//   2: '禁用',
// };
// 货主状态
// export const shipperStatusMap = {
//   1: '失效',
//   2: '正常',
// };
// 认证状态 0 未审核 1 已审核 2审核未通过   展示归纳为=>已认证1（运营审核通过) 0|2 未认证
// export const shipperAuthStatusMap = {
//   1: '已认证',
//   2: '未认证',
//   0: '未认证',
// };
// 是否可卸结
// export const unhitchMap = {
//   1: '是',
//   2: '否',
// };
// 员工角色 	人员类型 1:货业务 2:车业务 3:调度 4:查前线+调度
// export const staffRoleTypeMap = {
//   1: '货业务', // 货业务
//   2: '车业务', // 车业务
//   3: '调度',
//   4: '业务+调度',
// };
