import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import carMonitor from './modules/carMonitor';
import settings from './modules/settings';
import dictionary from './modules/dictionary';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    common,
    carMonitor,
    settings,
    dictionary,
  },
});

export default store;
