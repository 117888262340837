<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
@import '@/assets/scss/index.scss';
</style>

<style lang="scss" scoped>
#app {
  height: 100%;
}
</style>
