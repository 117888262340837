<!--
   ** limit: 上传限制数量
   ** keyList: Array 所有在列表中的key 数组
   ** mode: preview 显示 | edit 编辑
-->
<template>
  <div class="_upload">
    <div class="_upload-c">
      <div v-if="mode === 'preview' && keyList.length === 0" class="list no-pic">
        <i class="el-icon-picture-outline" />
        <span>{{ isImg ? "暂无图片" : "暂无文件" }}</span>
      </div>
      <div v-for="(key, key_index) in keyList" v-else :key="key_index" class="list preview">
        <div class="operate">
          <span @click="viewImg(key, key_index)"><i class="el-icon-zoom-in" /></span>
          <span v-if="mode === 'edit'" @click="deleteImg(key_index)">
            <i class="el-icon-delete" />
          </span>
        </div>
        <img :src="`${tinyimg_url}browse?fileCode=${key}`" alt="" @error="imgShowError($event, key_index)">
      </div>

      <div v-if="mode === 'edit' && keyList.length < limit" class="up-c">
        <div class="list">
          <label v-if="!percent" title="上传">
            <span class="icon-upload" />
            <span v-if="placeholder" class="up-placeholder">
              {{ placeholder }}
            </span>
            <input :accept="accept" class="upload-input" type="file" @change="uploadImg">
          </label>
          <el-progress v-if="percent" class="percent" type="circle" :width="84" :percentage="percent" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const upload_url = '/configure/file/upload';
const tinyimg_url = process.env.VUE_APP_MINIO_URL;
const highimg_url = process.env.VUE_APP_MINIO_URL;
import pdf from '@/assets/images/common/pdf.png';
export default {
  name: 'Pic',
  props: {
    // 图片上传结束 文件格式以什么类型传出(默认 数组)
    keyListType: {
      type: String,
      default: 'Array',
    },
    limit: {
      type: Number,
      default: 10,
    },
    keyList: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: 'edit',
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/gif',
      // application/pdf'
    },
    name: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '请上传',
    },
    //	0-通用，1-证件类
    imgType: {
      type: [String, Number],
      default: 0,
    },
    // 是否开启压缩
    isCompress: {
      type: Boolean,
      default: true,
    },
    // 压缩比例
    isCompresssRatio: {
      type: [String, Number],
      default: 0.8,
    },
  },
  data() {
    return {
      tinyimg_url,
      highimg_url,
      percent: 0,
      isFile: [false], // 当图片
      isImg: true,
    };
  },
  computed: {
    fileReg() {
      const acceptItems = this.accept.split(',');
      const str = acceptItems
        .map((val) => {
          return val.trim().replace('/', '\\/');
        })
        .join('|');
      return new RegExp(`(${str})`);
    },
  },
  mounted() {
    this.curkeyList = JSON.parse(JSON.stringify(this.keyList));
  },
  methods: {
    ajax(formData) {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('post', upload_url, true);
        xhr.upload.onprogress = (e) => {
          this.percent = Math.round((e.loaded / e.total) * 100);
        };
        xhr.upload.onloadstart = () => {
          this.percent = 0;
        };
        xhr.onload = () => {
          this.percent = 0;
        };
        xhr.send(formData);
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              resolve(JSON.parse(xhr.responseText));
            } else {
              reject(`${xhr.status}:${xhr.statusText}`);
            }
          }
        };
      });
    },
    imgShowError(e, index) {
      e.target.src = pdf;
      this.isFile[index] = true;
    },
    // 点击上传图片
    uploadImg(e) {
      const file = e.target.files[0];

      if (!this.fileReg.test(file.type)) {
        this.$baseMessage('上传的文件格式不正确，请重选符合规定的文件！', 'error');
        e.target.value = '';
        return;
      }
      // // 如果图片大小超过20M 提示
      // const isLt20M = file.size / 1024 / 1024 > 20;
      // if (isLt20M) {
      //   this.$baseMessage('图片大小超过20M，请压缩后重新上传！', 'error');
      //   return false;
      // }
      // 超过1M压缩上传
      if (file.size > 1 * 1024 * 1024 && this.isCompress) {
        this.compressAndUploadImage(file);
      } else {
        this.uploadImage(file);
      }
    },
    // 压缩超过1M得图片
    compressAndUploadImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          const maxWidth = 800;
          const maxHeight = 800;

          let width = img.width;
          let height = img.height;

          if (width > maxWidth || height > maxHeight) {
            const ratio = Math.min(maxWidth / width, maxHeight / height);
            width *= ratio;
            height *= ratio;
          }

          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob((blob) => {
            const compressedFile = new File([blob], file.name, { type: file.type });
            // 压缩完如果图片大小超过10M 提示
            const isLt10M = compressedFile.size / 1024 / 1024 > 10;
            if (isLt10M) {
              this.$baseMessage('图片过大，请压缩后重新上传！', 'error');
              return false;
            }
            this.uploadImage(compressedFile);
          }, file.type, this.isCompresssRatio);
        };
      };

      reader.readAsDataURL(file);
    },
    // 图片上传服务器
    uploadImage(file) {
      if (/image/.test(file.type)) {
        this.isFile[this.keyList.length] = false;
        this.isImg = true;
      }
      // 创建FormData对象，并向其中添加文件和其他表单数据
      const formData = new FormData();
      formData.append('file', file);
      // formData.append('type', this.imgType);
      this.ajax(formData)
        .then((res) => {
          const keyList = this.keyList;
          keyList.push(res.data);
          this.$emit('keyList:update', keyList);
          // e.target.value = '';
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 压缩超过1M得图片
    // handlePictureCardPreview(file) {
    //   return new Promise(resolve => compress(file, val => {
    //     resolve(val);
    //   }));
    // },
    // async uploadImg2(e) {
    //   const files = e.target.files[0];
    //   const formData = new FormData();
    //   let needFiles;
    //   if (!this.fileReg.test(files.type)) {
    //     this.$baseMessage('上传的文件格式不正确，请重选符合规定的文件！', 'error');
    //     e.target.value = '';
    //     return;
    //   }
    //   // 如果图片大小超过10M 提示
    //   const isLt10M = files.size / 1024 / 1024 > 10;
    //   if (isLt10M) {
    //     this.$baseMessage('图片大小超过10M，请压缩后重新上传！', 'error');
    //     return false;
    //   }
    //   // 如果图片大小超过1M 进行压缩
    //   const isLt1M = files.size / 1024 / 1024 > 1;
    //   if (isLt1M) {
    //     needFiles = await this.handlePictureCardPreview(files);
    //   } else {
    //     needFiles = files;
    //   }
    //   if (/image/.test(files.type)) {
    //     this.isFile[this.keyList.length] = false;
    //     this.isImg = true;
    //   }
    //   formData.append('file', needFiles);
    //   formData.append('type', this.imgType);
    //   this.ajax(formData)
    //     .then((res) => {
    //       const keyList = this.keyList;
    //       // keyList.push(res.a);
    //       keyList.push(res.data.url);

    //       this.$emit('keyList:update', keyList);
    //       e.target.value = '';
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    viewImg(key, key_index) {
      if (this.isFile[key_index]) {
        window.open(`${highimg_url}browse?fileCode=${key}`, '文件预览');
      } else {
        this.$viewer({ urlList: [`${highimg_url}browse?fileCode=${key}`] });
      }
    },
    deleteImg(index) {
      const keyList = this.keyList;
      keyList.splice(index, 1);
      this.isFile.splice(index, 1);
      this.$emit('keyList:update', keyList);
      this.percent = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
._upload {
  display: inline-block;
  ._upload-c {
    display: inline-block;
    vertical-align: top;
    .list {
      position: relative;
      display: inline-block;
      width: 120px;
      height: 90px;
      border: 1px solid #c0ccda;
      border-radius: 4px;
      margin: 0 10px 10px 0;
    }
    .percent {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .preview {
      position: relative;
      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
        // object-fit: cover;
      }
      .operate {
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 10;
        left: 0;
        top: 0;
        background-color: rgba(0, 0, 0, 0.5);
        opacity: 0;

        transition: opacity 0.3s;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          opacity: 1;
        }
        span {
          cursor: pointer;
          margin: 0 6px;
          font-size: 20px;
        }
      }
    }
    .up-c {
      display: inline-block;
      vertical-align: top;
      label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        width: 100%;
        height: 100%;
        .upload-input {
          display: none;
        }
        .icon-upload {
          position: absolute;
          top: 40%;
          left: 50%;
          transform: translate(-50%, -50%);
          &::before {
            position: absolute;
            content: "";
            width: 30px;
            height: 2px;
            border-radius: 2px;
            background: #c0ccda;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
          &::after {
            position: absolute;
            content: "";
            width: 30px;
            height: 2px;
            border-radius: 2px;
            background: #c0ccda;
            transform: translate(-50%, -50%) rotate(90deg);
            left: 50%;
            top: 50%;
          }
        }
      }
    }
  }
}
.no-pic {
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 12px;
  i {
    font-size: 28px;
    margin-bottom: 5px;
  }
}
.el-icon-files {
  position: absolute;
  font-size: 42px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  color: #666;
}
.up-placeholder {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, 0);
  line-height: 1.1;
  font-size: 12px;
  color: #c0ccda;
  text-align: center;
  width: 100%;
}
</style>
