import request from '@/plugins/axios';
const prefix = '/auv';
/* 获取用户列表 */
export const getSysTemUserList = (data) => {
  return request.post(`/user/middle/employ/list`, data).then((res) => {
    return res;
  });
};
/* 获取全部部门 */
export const getSysTemDepartList = (data) => {
  return request.post(`${prefix}/user/depart/list`, data).then((res) => {
    return res;
  });
};
/* 用户添加 */
export const addSysTemUser = (data) => {
  return request.post(`${prefix}/user/add`, data).then((res) => {
    return res;
  });
};
/* 获取用户详情 */
export const getSysTemUserInfo = (data) => {
  return request.get(`${prefix}/user/middle/base/info`, data).then((res) => {
    return res;
  });
};

/* 编辑人员 */
export const updateSysTemUser = (data) => {
  return request.post(`${prefix}/user/update`, data).then((res) => {
    return res;
  });
};

/* 获取岗位列表 */
export const getSysTemPostList = (data) => {
  return request.post(`${prefix}/user/post/list`, data).then((res) => {
    return res;
  });
};

/* ----------企业人员关联企业列表------------ */
export const companyUserRellist = (data) => {
  return request.post(`/user/middle/company/rel/list`, data).then((res) => {
    return res;
  });
};
/* 岗位人员列表 */
export const getSysTemPostUserList = (data) => {
  return request.post(`${prefix}/user/post/user/list`, data).then((res) => {
    return res;
  });
};
/* 岗位权限获取(登录人) */
export const getUserMyPostMenuList = () => {
  return request.get(`${prefix}/user/my/post/menu/list`).then((res) => {
    return res;
  });
};
