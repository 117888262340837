import * as valid from '@/utils/validate';
// import store from '@/store';

const validate = (type, value) => {
  if (type === 'mobile') {
    const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    return reg.test(value);
  }
  if (type === 'idsn') {
    return valid.isID(value);
  }
  if (type === 'carNum') {
    const reg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[A-Z0-9挂学警港澳]{1}$/;
    return reg.test(value);
  }
  if (type === 'guaNum') {
    const reg = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{4}[挂]{1}$/;
    return reg.test(value);
  }
};

// const userExisted = async(value, type) => {
// const params = {
//   c: type,
// };
// if (type === 0) {
//   params.a = value;
// }
// if (type === 1) {
//   params.b = value;
// }
// let isExisted = true;
// const res = await store.dispatch('common/userValid', params);
// if (res && res.a && res.a === 200 && res.d) {
//   if (res.d.a === value || res.d.b === value) {
//     isExisted = true;
//   } else {
//     isExisted = false;
//   }
// }
// return false;
// };

// const carExisted = async(value, type) => {
//   const params = {
//     a: value,
//     b: type,
//   };
//   let isExisted = true;
//   const res = await store.dispatch('common/carValid', params);
//   if (res && res.a && res.a === 200 && res.d) {
//     if (res.d.a === value) {
//       isExisted = true;
//     } else {
//       isExisted = false;
//     }
//   }
//   return isExisted;
// };

const pathToName = (path) => {
  const arr = path.split('/');
  let name = '';
  arr.forEach((val) => {
    if (val && val !== ' ') {
      name += val.trim().toLowerCase().replace(val[0], val[0].toUpperCase());
    }
  });
  return name;
};

export {
  validate,
  // userExisted,
  // carExisted,
  pathToName,
};
