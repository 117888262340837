const financial = [
  {
    meta: {
      code: '71',
      keepAlive: true,
    },
    name: '用户管理',
    path: '/system/user',
    component: () => import('@/views/system/user'),
  },
  {
    meta: {
      code: '72',
      keepAlive: true,
    },
    name: '角色管理',
    path: '/system/role',
    component: () => import('@/views/system/role'),
  },
  {
    meta: {
      code: '73',
      keepAlive: true,
    },
    name: '权限管理',
    path: '/system/permission',
    component: () => import('@/views/system/permission'),
  },
  {
    meta: {
      code: '74',
      keepAlive: true,
    },
    name: '用户操作日志',
    path: '/system/logs',
    component: () => import('@/views/system/logs'),
  },
];

export default financial;
