const goodsOrder = [
  // {
  //   meta: {
  //     code: '41',
  //     keepAlive: true,
  //   },
  //   name: '货源大厅',
  //   path: '/goodsOrder/supplyHall',
  //   component: () => import('@/views/goodsOrder/supplyHall'),
  // },
  // {
  //   meta: {
  //     code: '42',
  //     keepAlive: true,
  //   },
  //   name: '货单列表',
  //   path: '/goodsOrder/orderList',
  //   component: () => import('@/views/goodsOrder/orderList'),
  // },
  // {
  //   meta: {
  //     code: '42',
  //     keepAlive: true,
  //   },
  //   name: '货单详情',
  //   path: '/goodsOrder/orderList/detail',
  //   component: () => import('@/views/goodsOrder/orderList/detail'),
  // },
  {
    meta: {
      code: '430',
      keepAlive: true,
    },
    name: '货源承接',
    path: '/goodsOrder/supplyTask',
    component: () => import('@/views/goodsOrder/supplyTask'),
  },
  {
    meta: {
      code: '430',
      keepAlive: true,
    },
    name: '货源详情-货源承接',
    path: '/goodsOrder/supplyTask/detail',
    component: () => import('@/views/goodsOrder/supplyTask/detail'),
  },
  {
    meta: {
      code: '440',
      keepAlive: true,
    },
    name: '货源找车',
    path: '/goodsOrder/supplyLookCar',
    component: () => import('@/views/goodsOrder/supplyLookCar'),
  },
  {
    meta: {
      code: '440',
      keepAlive: true,
    },
    name: '货源详情-货源找车',
    path: '/goodsOrder/supplyLookCar/detail',
    component: () => import('@/views/goodsOrder/supplyLookCar/detail'),
  },
];

export default goodsOrder;
