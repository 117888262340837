const wsUrl = process.env.VUE_APP_WS_URL;
// const shareUrl = process.env.VUE_APP_shareUrl;
// const msdsUrl = process.env.VUE_APP_msdsUrl;
const dataViewUrl = process.env.VUE_APP_dataViewUrl;

module.exports = {
  // 产品ID
  productId: 2022,
  // 部署时的URL
  publicPath: '/',
  // 生产环境构建文件的目录名
  outputDir: 'dist',
  // 放置生成的静态资源 (js、css、img、fonts) 的 (相对于 outputDir 的) 目录。
  assetsDir: 'static',
  // 标题 （包括初次加载雪花屏的标题 页面的标题 浏览器的标题）
  title: 'AUV',
  // 标题简写（必须修改）
  abbreviation: 'AUV',
  // 开发环境端口号
  devPort: '8284',
  // 版本号
  version: process.env.VUE_APP_VERSION,
  // 加载时显示文字
  loadingText: '正在加载中...',
  // 消息显示时间
  messageDuration: 2000,
  // 是否显示logo
  logo: true,
  // 是否只保持一个子菜单的展开
  uniqueOpened: true,
  // 默认展开的菜单path，使用逗号隔开建议只展开一个
  defaultOopeneds: [],
  // 需要加loading层的请求，防止重复提交
  debounce: [],
  buildZip: false,
  // msdsUrl, // http://120.48.19.218:9540
  wsUrl,
  dataViewUrl, // 正式
  // shareUrl,
  // dataViewUrl: "http://180.76.231.194:9542", // 测试 开发
};
