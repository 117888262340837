import menuList from './menuList';
import { pathToName } from '@/assets/js/libs';
import { login } from '@/api/user';
import poundList from '@/api/pound';

import { getUserMyPostMenuList } from '@/api/systemUser';
import { getToken, setToken, removeToken, removeUserId, removeCompanyId, getNavList, setNavList, removeNavList, setUserId, getUserId, setCompanyId, getCompanyId, setUserInfo, getUserInfo, removeUserInfo } from '@/utils/auth';
import { Message } from 'element-ui';
const store = {
  namespaced: true,
  state: {
    AUV_TOKEN: getToken(),
    AUV_USER_ID: getUserId() || 0,
    AUV_CPY_ID: getCompanyId() || 0,
    AUV_ROLE_CODE: '',
    userInfo: getUserInfo() ? JSON.parse(getUserInfo()) : {},
    navList: getNavList() ? JSON.parse(getNavList()) : [{ title: '首页', path: '/' }],
    menuList,
    crumbList: [],
    pathPermissions: [],
    collapse: false,
    loading: false,
    aliveList: ['Index'],
    bandExportDialog: false, // 磅单导出列表弹窗显隐
    bandExportingLength: 0, // 磅单导出中的数量
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.AUV_TOKEN = token;
    },
    SET_USER_ID: (state, userId) => {
      state.AUV_USER_ID = userId;
    },
    SET_CPY_ID: (state, companyId) => {
      state.AUV_CPY_ID = companyId;
    },
    SET_ROLE_CODE: (state, roleCode) => {
      state.AUV_ROLE_CODE = roleCode;
    },
    SET_USER_INFO(state, userInfo) {
      state.userInfo = userInfo;
      setUserInfo(JSON.stringify(userInfo));
    },
    SET_PATH_PERMISSIONS(state, pathPermissions) {
      state.pathPermissions = pathPermissions;
    },
    login(state) {
      state.navList = [{ title: '首页', path: '/' }];
      state.pathPermissions = [];
      // state.btnCodes = [];
    },
    layout(state) {
      state.navList = [{ title: '首页', path: '/' }];
      state.pathPermissions = [];
      // state.btnCodes = [];
      // Cookie.remove('AUV_USER_ID');
      // Cookie.remove('AUV_CPY_ID');
      // Cookie.remove('AUV_TOKEN');
      removeToken();
      // Cookie.remove('AUV_NAV_LIST');
      removeNavList();
      state.AUV_TOKEN = '';
      // state.AUV_USER_ID = 0;
      // state.AUV_CPY_ID = 0;
    },
    setCrumbList(state, list) {
      state.crumbList = list;
    },
    pushCrumb(state, crumb) {
      state.crumbList.push(crumb);
    },
    setCollapse(state, value) {
      state.collapse = value;
    },
    setBandExportDialog(state, value) {
      state.bandExportDialog = value;
    },
    setBandExportingLength(state, value) {
      state.bandExportingLength = value;
    },
    addNav(state, data) {
      let has = false;
      state.navList.some((val) => {
        if (val.path === data.path) {
          has = true;
          return true;
        }
      });
      if (!has) {
        state.navList.push(data);
      } else {
        const result = state.navList.findIndex(d => d.path === data.path);
        if (state.navList[result].query) {
          state.navList[result].query = data.query;
        }
      }
      if (state.navList.length > 10) {
        const removeNav = state.navList.splice(1, 1);
        this.commit('common/removeAlive', pathToName(removeNav[0].path));
      }
      setNavList(JSON.stringify(state.navList));
      // Cookie.set('AUV_NAV_LIST', JSON.stringify(state.navList));
    },
    removeNav(state, data) {
      state.navList.some((val, index) => {
        if (val.path === data) {
          state.navList.splice(index, 1);
          return true;
        }
      });
      setNavList(JSON.stringify(state.navList));
      // Cookie.set('AUV_NAV_LIST', JSON.stringify(state.navList));
    },
    clearNav(state) {
      state.navList = [{ title: '首页', path: '/' }];
    },
    addAlive(state, name) {
      let hasName = false;
      state.aliveList.some((val) => {
        if (val === name) {
          hasName = true;
          return true;
        }
      });
      if (!hasName) {
        setTimeout(() => {
          state.aliveList.push(name);
        }, 500);
      }
    },
    removeAlive(state, name) {
      state.aliveList.some((val, index) => {
        if (val === name) {
          state.aliveList.splice(index, 1);
          return true;
        }
      });
    },
  },
  actions: {
    // 登录
    login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          // state.navList = [{ title: '首页', path: '/' }];
          if (res.token) {
            setToken(res.token);
            commit('SET_TOKEN', res.token);
            setUserId(res.userId);
            commit('SET_USER_ID', res.userId);
            setCompanyId(res.companyId);
            commit('SET_CPY_ID', res.companyId);
            commit('SET_USER_INFO', res);
            // commit('SET_USER_INFO', res);
          }
          resolve(res);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 获取用户信息
    GetInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        let roleCode;
        if (this.state.common.userInfo && this.state.common.userInfo.post) {
          roleCode = this.state.common.userInfo.post.split(',')[0];
        } else {
          roleCode = 'PT007';
        }
        this.state.common.AUV_ROLE_CODE = roleCode;
        getUserMyPostMenuList()
          .then((res) => {
            if (res.length) {
              const permissions = res;
              commit('SET_PATH_PERMISSIONS', permissions);
              resolve(res);
            } else {
              Message.error('账号异常,未返回角色信息roleCode编码');
            }
          })
          .catch((error) => {
            console.log(error, 'error');
            reject(error);
          });

        // const res = {
        //   roleCode: roleCode,
        // };
        // // 后台不返回菜单权限 前端mock菜单code // roleCode	角色编码
        // // 货主业务员:PT001 ,车队业务员:PT002 ,车队调度:PT003 ,AUV前线(货客服):PT004 ,AUV小五(车客服):PT005 运营 PT006 管理员 PT007
        // const permissionsCodeObj = {
        //   PT001: ['10', '200', '210', '220', '300', '310', '320', '330', '340', '350', '360', '370', '380', '400', '410', '420', '430', '440', '500', '510', '600', '610', '620', '700', '710', '720', '730', '740'],
        //   PT002: ['10'],
        //   PT003: ['10'],
        //   PT004: ['10', '200', '220', '300', '310', '320', '330', '400', '410', '430', '500', '510', '520', '530', '540'], // AUV前线(货客服):PT004
        //   PT005: ['10', '200', '220', '300', '340', '350', '360', '370', '380', '400', '410', '440', '500', '510', '520', '530', '540'], // AUV小五(车客服)
        //   PT006: ['10', '200', '210', '400', '410', '420', '500', '510', '520', '600', '610', '620', '700', '710', '720', '730', '740'], // 运营 PT006
        //   PT007: ['10', '200', '210', '220', '230', '300', '310', '320', '330', '340', '350', '360', '370', '380', '390', '400', '410', '420', '430', '440', '500', '510', '520', '530', '540', '600', '610', '620', '630', '700', '710', '720', '730', '740'], // 管理员 PT007
        // };
        // if (res.roleCode) {
        //   this.state.common.AUV_ROLE_CODE = res.roleCode;
        //   const permissions = permissionsCodeObj[res.roleCode];
        //   // const permissions = permissionsCodeObj.PT007;
        //   commit('SET_PATH_PERMISSIONS', permissions);
        //   // commit('SET_USER_INFO', res);
        //   // setUserId(res.id);
        //   // commit('SET_USER_ID', res.id);
        //   resolve(res);
        // } else {
        //   Message.error('账号异常,未返回角色信息roleCode编码');
        // }
      });
    },
    // 获取磅单图片批量下载进行中任务数量查询
    getBandExportIngNum({ commit }, params) {
      return new Promise((resolve, reject) => {
        poundList.deldownloadPoundNum(params).then(res => {
          commit('setBandExportingLength', res);
          resolve(res);
        }).catch(error => {
          reject(error);
        });
      });
    },
    // 前端 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '');
        commit('SET_USER_ID', '');
        // commit('SET_CPY_ID', '');
        commit('SET_USER_INFO', {});
        // commit('SET_BTNCODE', []);
        commit('SET_PATH_PERMISSIONS', []);
        commit('clearNav');

        removeToken();
        removeUserInfo();
        removeUserId();
        removeCompanyId();
        removeNavList();
        resolve();
      });
    },
  },
};

export default store;
