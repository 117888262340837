import business from './business';
import basicData from './basicData';
import goodsOrder from './goodsOrder';
import transport from './transport';
// import financial from './financial';
import system from './system';

const common = [
  {
    meta: {
      code: '10',
    },
    name: '首页',
    path: '/',
    component: () => import('@/views/index'),
  },
  {
    meta: {
      code: '10',
    },
    name: '消息中心',
    path: '/msgCenter',
    component: () => import('@/views/msgCenter'),
  },
  {
    name: '404',
    path: '/404',
    component: () => import('@/views/404'),
  },
  ...business,
  ...basicData,
  // ...order,
  ...goodsOrder,
  ...transport,
  // ...financial,
  ...system,
];

export default common;
