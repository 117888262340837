import * as valid from './validate';

// 去掉输入框中的空格
export function trim(str) {
  if (str !== '') {
    str = str.replace(/^\s+|\s+$/g, '');
  }
  return str;
}

// 验证密码
export const validatePwd = (rule, value, callback) => {
  if ((rule.required && !value) || (value && !valid.isPassword(value))) {
    callback(new Error('请输入正确的密码，密码长度6-8位,字母或者数字组成'));
  } else {
    callback();
  }
};
export const validatePassward = (msg) => {
  return function(rule, value, callback) {
    if ((rule.required && !value) || (value && !valid.isPassword(value))) {
      callback(new Error(msg || '请输入正确的密码'));
    } else {
      callback();
    }
  };
};
export function validateRequired(message) {
  return function(rule, value) {
    if ((!value && typeof value !== 'number') || (Array.isArray(value) && value.length === 0)) {
      return Promise.reject(new Error(message));
    } else {
      return Promise.resolve();
    }
  };
}
// 验证码手机号
export const validateTel = (rule, value, callback) => {
  if ((rule.required && !value) || (value && !valid.isPhone(value))) {
    callback(new Error('请输入正确的手机号'));
  } else {
    callback();
  }
};
export function validateLength(setLength) {
  return function(rule, value) {
    if ((rule.required && !value) || (value && value.length !== setLength)) {
      return Promise.reject(new Error(`验证码长度为${setLength}位`));
    } else {
      return Promise.resolve();
    }
  };
}
// 验证身份证
export const validateID = (rule, value, callback) => {
  if ((rule.required && !value) || (value && !valid.isID(value))) {
    callback(new Error('请输入正确的身份证号'));
  } else {
    callback();
  }
};

// 验证车牌号
export const validateCarNO = (rule, value, callback) => {
  if ((rule.required && !value) || (value && !valid.isCarNo(value))) {
    callback(new Error('请输入正确的车牌号'));
  } else {
    callback();
  }
};
// 验证码电话号
export const validateCell = (rule, value, callback) => {
  if ((rule.required && !value) || (value && !valid.isCell(value))) {
    callback(new Error('请输入正确的电话号码'));
  } else {
    callback();
  }
};
// 验证挂车牌号
export const validateGuaCarNO = (rule, value, callback) => {
  if ((rule.required && !value) || (value && !valid.isCarNo1(value))) {
    callback(new Error('请输入正确的挂车牌号'));
  } else {
    callback();
  }
};

// 验证车辆识别码
export const validateCarDiscernNo = (rule, value, callback) => {
  if (value) {
    if (trim(value)) {
      if (!valid.validatAlphanumeric(trim(value))) {
        callback(new Error('请输入正确的车辆识别码'));
      } else {
        callback();
      }
    } else {
      callback();
    }
  } else {
    callback();
  }
};

// 验证核载吨数
export const validateCarTon = (rule, value, callback) => {
  const reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
  if (!reg.test(value) || parseInt(value, 10) > 1000) {
    callback(new Error('请输入正确的核载吨数'));
  } else {
    callback();
  }
};

// 验证正整数
export const validateNumber = (rule, value, callback) => {
  const reg = /^[+]{0,1}(\d+)$/;
  if (value && !reg.test(value)) {
    callback(new Error('请输入正确的整数'));
  } else {
    callback();
  }
};

/*
大于0 保留2位小数
小于 6位数
*/
export function validateFloat(rule, value, callback) {
  const reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/;
  if ((rule.required && !value) || (value && !reg.test(value)) || parseFloat(value) > 10000000) {
    callback(new Error('请输入合理的数'));
  } else {
    callback();
  }
}
export function validatePrice(msg) {
  return function(rule, value, callback) {
    const reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/;
    if ((rule.required && !value) || (value && !reg.test(value)) || parseFloat(value) > 100000000) {
      callback(new Error(msg || '请输入合理的值'));
    } else {
      callback();
    }
  };
}
export function validatePrice3(msg) {
  return function(rule, value, callback) {
    const reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,3}\b)/;
    if ((rule.required && !value) || (value && !reg.test(value)) || parseFloat(value) > 100000000) {
      callback(new Error(msg || '请输入合理的值'));
    } else {
      callback();
    }
  };
}
export function validatePrice4(msg) {
  return function(rule, value, callback) {
    const reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/;
    if ((rule.required && !value) || (value && !reg.test(value)) || parseFloat(value) > 100000000) {
      callback(new Error(msg || '请输入合理的值'));
    } else {
      callback();
    }
  };
}

/*
大于0 保留2位小数
小于 6位数
*/
export function validateInt(rule, value, callback) {
  const reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/;
  if ((rule.required && !value) || (value && !reg.test(value)) || parseFloat(value) > 10000000) {
    callback(new Error('请输入合理的吨数'));
  } else {
    callback();
  }
}
// 整数2位
export function validateInteger(msg) {
  return function(rule, value, callback) {
    const reg = /[1-9]{1,2}/;
    if ((rule.required && !value) || (value && !reg.test(value)) || String(value).length > 2) {
      callback(new Error(msg || '请输入合理的数'));
    } else {
      callback();
    }
  };
}
// 里程
export function validateMileage(msg) {
  return function(rule, value, callback) {
    const reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,3}\b)/;
    if ((rule.required && !value) || (value && !reg.test(value)) || parseFloat(value) > 10000000) {
      callback(new Error(msg || '请输入合理的里程数'));
    } else {
      callback();
    }
  };
}
/*
大于0 保留3位小数
小于 6位数
*/
export function validateInt3(rule, value, callback) {
  const reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,3}\b)/;
  if ((rule.required && !value) || (value && !reg.test(value)) || parseFloat(value) > 10000000) {
    callback(new Error('请输入合理的吨数'));
  } else {
    callback();
  }
}
// 油耗
export function validateOil(msg) {
  return function(rule, value, callback) {
    const reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/;
    if ((rule.required && !value) || (value && !reg.test(value)) || parseFloat(value) > 10000000) {
      callback(new Error(msg || '请输入合理的油耗'));
    } else {
      callback();
    }
  };
}
// 验证吨数
export function validateDun(msg) {
  return function(rule, value, callback) {
    const reg = /^(0|[1-9]\d*)(\s|$|\.\d{1,3}\b)/;
    const valueString = value ? value.toString() : '';
    const index = valueString.indexOf('.');
    let flag = false;
    const integer = valueString.slice(0, index);
    if (index >= 0 && integer.length > 6) {
      flag = true;
    } else if (index < 0 && valueString.length > 6) {
      flag = true;
    }
    if ((rule.required && !value) || (value && !reg.test(value)) || parseFloat(value) > 10000000 || flag) {
      callback(new Error(msg || '请输入合理的吨数'));
    } else {
      callback();
    }
  };
}
/*
  损耗
*/
export function validateLose(rule, value, callback) {
  if ((rule.required && value === '') || parseFloat(value) < 0) {
    callback(new Error('请输入合理的损耗'));
  } else {
    callback();
  }
}
// 名称校验
export function validateObject(msg) {
  return function(rule, value, callback) {
    if (value && (!Object.keys(value) || Object.keys(value).length === 0)) {
      return callback(new Error(msg || '请输入必填项'));
    }
    callback();
  };
}
// 名称校验
export function validateName(msg) {
  return function(rule, value, callback) {
    if ((!value && rule.required) || (value && !/^[\u4E00-\u9FA5\(\（\）\)\：\.\-\:A-Za-z0-9]+$/.test(value))) {
      return callback(new Error(msg || '请输入合理的名称'));
    }
    callback();
  };
}
// 中文校验
export function validateChinese(msg) {
  return function(rule, value, callback) {
    if ((!value && rule.required) || (value && !/^[\u4E00-\u9FA5\(\（\）\)\：\:\、\，\,\。\《\》\.\-\:A-Za-z0-9\r\t\n]+$/.test(value))) {
      return callback(new Error(msg || '请输入合理的名称'));
    }
    callback();
  };
}
// cas码校验
export function validateCAS(msg) {
  return function(rule, value, callback) {
    if ((!value && rule.required) || (value && !/^(\d{2,6})\-(\d{2})\-(\d)$/.test(value))) {
      return callback(new Error(msg || '请输入合理的cas码'));
    }
    callback();
  };
}
// 字母+数字组合判断
export function validateNumberLetter(msg) {
  return function(rule, value, callback) {
    if ((!value && rule.required) || (value && !/^[A-Za-z0-9]+$/.test(value))) {
      return callback(new Error(msg || '请输入合理的值'));
    }
    callback();
  };
}
// 字母+数字组合判断
export function validatePower(msg) {
  return function(rule, value, callback) {
    if ((!value && rule.required) || (value && !/^([1-9]\d*)(\s|$|\.\d{1,2}\b)/.test(value))) {
      return callback(new Error(msg || '请输入合理的功率'));
    }
    callback();
  };
}
export function validateSim(msg) {
  return function(rule, value, callback) {
    if ((rule.required && !value) || (value && !valid.isPhone(value))) {
      callback(new Error(msg || '请输入正确的手机号'));
    } else {
      callback();
    }
  };
}
