<template>
  <div class="aside-c" :class="{ collapse: isCollapse }">
    <div class="aside-top" />
    <div class="menu-c">
      <el-menu
        class="menu-list"
        :default-active="currentPage"
        :collapse="isCollapse"
        :unique-opened="true"
      >
        <MenuTree :is-collapse="isCollapse" :list="menuList" />
      </el-menu>
    </div>
    <div class="toggle-c">
      <img class="icon" :class="{ collapse: isCollapse }" src="@/assets/images/menu/toggle.svg" @click="toggleCollapse">
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import MenuTree from '@/components/layout/menuTree.vue';

let currentPage = '';

export default {
  components: {
    MenuTree,
  },
  data() {
    return {
      currentPage: '/',
    };
  },
  computed: {
    ...mapState({
      menuList() { return this.$store.state.common.menuList; },
      isCollapse() { return this.$store.state.common.collapse; },
      pathPermissions() { return this.$store.state.common.pathPermissions; },
    }),
  },
  watch: {
    $route() {
      this.getCurrentPage(this.menuList);
    },
  },
  created() {
    this.menuListFormat(this.menuList);
    this.getCurrentPage(this.menuList);
  },
  methods: {
    ...mapMutations({
      setCollapse: 'common/setCollapse',
    }),
    getCurrentPage(list, data = {}) {
      currentPage = '';
      list.some((val) => {
        if (!val.hideInMenu && val.children && val.children.length > 0) {
          currentPage = this.getCurrentPage(val.children, val);
        } else {
          if (val.path === this.$route.path || data.path === this.$route.path) {
            if (val.hideInMenu) {
              currentPage = data.path;
            } else {
              currentPage = val.path;
            }
            return true;
          }
        }
      });
      if (currentPage) {
        this.currentPage = currentPage;
      }
    },
    hasChildren(list) {
      if (list && list.length > 0) {
        let useFul = false;
        list.some((val) => {
          if (!val.hideInMenu && this.pathPermissions.includes(val.code)) {
            useFul = true;
          }
        });
        return useFul;
      } else {
        return false;
      }
    },
    menuListFormat(list) {
      let hasPermission = false;
      let lock = false;
      list.forEach((val) => {
        if (this.hasChildren(val.children)) {
          val.hasPermission = this.menuListFormat(val.children);
          if (!lock && this.menuListFormat(val.children)) {
            hasPermission = this.menuListFormat(val.children);
            lock = true;
          }
        } else {
          val.hasPermission = this.pathPermissions.includes(val.code);
          if (!lock && this.pathPermissions.includes(val.code)) {
            hasPermission = this.pathPermissions.includes(val.code);
            lock = true;
          }
        }
      });
      return hasPermission;
    },
    toggleCollapse() {
      this.setCollapse(!this.isCollapse);
    },
  },
};
</script>

<style lang="scss" scoped>
.aside-c {
  width: 200px;
  height: 100%;
  background-color: #121A29;
  position: relative;
  overflow: hidden;
  transition: all 0.3s;
  &.collapse {
    width: 56px !important;
  }
  .aside-top {
    height: 40px;
  }
  .menu-c {
    width: 220px;
    height: calc(100vh - 100px - 40px);
    overflow-y: auto;
    .menu-list:not(.el-menu--collapse) {
      width: 200px;
    }
    .menu-list {
      width: 56px;
      border-right: 0;
    }
  }
  .toggle-c {
    width: 100%;
    height: 52px;
    background-color: #1B263D;
    padding: 16px 18px;
    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
      transition: all 0.3s;
      &.collapse {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
