<template>
  <el-col :xs="sm" :sm="sm" :md="md" :lg="lg" :xl="xl">
    <el-form-item :label="label" :prop="prop" v-bind="$attrs">
      <slot />
    </el-form-item>
  </el-col>
</template>
<script>
import Emitter from 'element-ui/src/mixins/emitter';
export default {
  name: 'SearchItem',
  mixins: [Emitter],
  props: {
    label: {
      type: String,
      default: '',
    },
    prop: {
      type: String,
      default: '',
    },
    hide: {
      type: Boolean,
      default: false,
    },
    sm: {
      type: Number,
      default: 12,
    },
    md: {
      type: Number,
      default: 12,
    },
    lg: {
      type: Number,
      default: 8,
    },
    xl: {
      type: Number,
      default: 6,
    },
  },
  mounted() {
    this.dispatch('SearchForm', 'searchItemAdded', {
      prop: this.prop,
      hide: this.hide,
    });
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-form-item {
    line-height: 36px;
  }
  .el-input {
    height: 36px;
  }
}
</style>
