import request from '@/plugins/axios';

/* 根据指定数据字典类型获取数据字典值  单个*/
export const getDictionary = (data) => {
  return request.post(`/configure/dict/type/${data.dictCode}`, data).then((res) => {
    return res;
  });
};

/* 根据指定数据字典类型获取数据字典值数组 多个*/
export const getDictionaryList = (data) => {
  return request.post(`/configure/dict/type/list`, data).then((res) => {
    return res;
  });
};
