// import Vue from 'vue';
import axios from 'axios';
// import Cookie from 'js-cookie';Loading
import { Message, Notification } from 'element-ui';
import { getToken } from '@/utils/auth';
import store from '../store';
import router from '@/router';
// let downloadLoadingInstance;
// 错误提示msg
let showInfo = false;
const errorMessage = (msg) => {
  if (!showInfo) {
    Message.error(msg);
    showInfo = true;
  }
  setTimeout(() => {
    showInfo = false;
  }, 500);
};
// 判断当前接口状态
const handleCode = (code, msg, noNeedErrAlertMsg) => {
  switch (+code) {
    // case 401:
    //   router.push({ path: '/401' }).catch(() => {});
    //   break;
    case 401:
      store.dispatch('common/FedLogOut').then(() => {
        router.push(`/login`);
        Notification.closeAll();
        Notification({
          title: '提示',
          message: '抱歉！登录状态已过期！',
          type: 'warning',
          duration: 5000,
        });
      });
      break;
    default:
      if (!noNeedErrAlertMsg) {
        errorMessage(msg || `服务器内部发生错误`);
      }
      break;
  }
};
// axios.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'; // 配置请求头
const instance = axios.create({
  // baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});
instance.interceptors.request.use(
  (config) => {
    let baseURL = process.env.VUE_APP_BASE_API;

    if (config.url.includes('/tRMessage')) {
      baseURL = '/';
    }
    config.headers.Authorization = store.state.common.AUV_TOKEN || getToken() || '';
    config.baseURL = baseURL;
    if (config && config.needLoading) {
      store.state.common.loading = true;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    const { data, config } = response;
    if (config.isImage) {
      return data;
    }
    const code = +data.code;
    const message = data.msg;

    if (code !== 200) {
      handleCode(code, message, config.noNeedErrAlertMsg);
      return Promise.reject('error');
    } else {
      // 项目整体默认接口成功不弹窗 needSuccessAlertMsg=>false 如需要弹窗设置needSuccessAlertMsg=>true
      if (config.needSuccessAlertMsg) {
        message && Message.success(message, 'success');
      }
      return data?.data;
    }
  },
  (error) => {
    const { message, response } = error;
    const newErrorMessage = message.includes('Request failed with status code')
      ? `${response.status} 服务器接口异常` : message;
    handleCode(response.status, newErrorMessage, response.config.noNeedErrAlertMsg);
    return Promise.reject(error);
  },
);

// 通用下载方法
// export function download(url, params, filename, config) {
//   downloadLoadingInstance = Loading.service({ text: '正在下载数据，请稍候', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)' });
//   return service.post(url, params, {
//     transformRequest: [(params) => { return tansParams(params); }],
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//     responseType: 'blob',
//     ...config,
//   }).then(async(data) => {
//     const isBlob = blobValidate(data);
//     if (isBlob) {
//       const blob = new Blob([data]);
//       saveAs(blob, filename);
//     } else {
//       const resText = await data.text();
//       const rspObj = JSON.parse(resText);
//       const errMsg = errorCode[rspObj.code] || rspObj.msg || errorCode['default'];
//       Message.error(errMsg);
//     }
//     downloadLoadingInstance.close();
//   }).catch((r) => {
//     console.error(r);
//     Message.error('下载文件出现错误，请联系管理员！');
//     downloadLoadingInstance.close();
//   });
// }

export default {
  get(url, params, options) {
    if (options && options.showLoading) {
      store.state.common.loading = true;
    }
    return instance.get(url, { params: params }, options).then((res) => {
      return res;
    }).finally(() => {
      if (store.state.common.loading) {
        setTimeout(() => {
          store.state.common.loading = false;
        }, 300);
      }
    });
  },
  post(url, params, options) {
    if (options && options.showLoading) {
      store.state.common.loading = true;
    }
    return instance.post(url, params, options).then((res) => {
      return res;
    }).finally(() => {
      if (store.state.common.loading) {
        setTimeout(() => {
          store.state.common.loading = false;
        }, 300);
      }
    });
  },
  put(url, params, options) {
    if (options && options.showLoading) {
      store.state.common.loading = true;
    }
    return instance.put(url, params, options).then((res) => {
      return res;
    }).finally(() => {
      if (store.state.common.loading) {
        setTimeout(() => {
          store.state.common.loading = false;
        }, 300);
      }
    });
  },
  delete(url, params, options) {
    if (options && options.showLoading) {
      store.state.common.loading = true;
    }
    return instance.delete(url, { params: params }, options).then((res) => {
      return res;
    }).finally(() => {
      if (store.state.common.loading) {
        setTimeout(() => {
          store.state.common.loading = false;
        }, 300);
      }
    });
  },
};
