<template>
  <div v-clickoutside="handleClose" class="query-select" @click.stop="toggleMenu">
    <template v-if="multiple">
      <div class="qs-tags" :style="{ 'max-width': inputWidth - 32 + 'px', width: '100%' }">
        <div class="flex">
          <el-tag
            v-for="item in selectedRows"
            :key="item[valueKey]"
            disable-transitions
            type="info"
            :closable="!disabled"
            size="mini"
            @close="onDeleteTag($event, item)"
          >
            <span>{{ item[valueLabel] }}</span>
          </el-tag>
        </div>
      </div>
    </template>
    <el-input
      ref="reference"
      v-model="selectedLabel"
      :placeholder="placeholderLabel"
      :clearable="clearable"
      readonly
      :disabled="disabled"
      autocomplete="off"
      @focus="handleFocus"
      @blur="handleBlur"
      @mouseenter.native="inputHovering = true"
      @mouseleave.native="inputHovering = false"
    >
      <template slot="suffix">
        <i v-show="!showClose" :class="['el-input__icon', 'el-icon-' + iconClass]" />
        <i v-if="showClose" class="el-input__icon el-icon-circle-close" @click="handleClearClick" />
      </template>
    </el-input>
    <transition name="el-zoom-in-top" @after-leave="doDestroy">
      <dropdown v-show="visible" ref="popper">
        <!-- 下拉详情 -->
        <div ref="qsContent" class="qs-content" :style="{ width: width }">
          <div v-if="showQuery" class="qs-query">
            <el-form ref="searchForm" class="query-form" onsubmit="return false;" size="mini" :label-width="labelWidth" :model="searchForm">
              <template v-for="(field,index) in searchFields">
                <template v-if="field.type==='select'">
                  <el-form-item :key="index + '_fi_' + field.field" :label="field.label" :prop="field.field">
                    <el-select
                      v-model="searchForm[field.field]"
                      placeholder="请选择"
                      clearable
                      :popper-append-to-body="false"
                      @change="changeSelect($event, field.field)"
                    >
                      <el-option
                        v-for="item in field.selectDataList"
                        :key="item[field.selectObj.value]||item.value"
                        :label="item[field.selectObj.label]||item.value"
                        :value="item[field.selectObj.value]||item.value"
                      />
                    </el-select>
                  </el-form-item>
                </template>
                <template v-else-if="field.type==='selectMultiple'">
                  <el-form-item :key="index + '_fi_' + field.field" :label="field.label" :prop="field.field">
                    <el-select
                      v-model="searchForm[field.field]"
                      placeholder="请选择"
                      multiple
                      clearable
                      :popper-append-to-body="false"
                      @change="changeSelect($event, field.field)"
                    >
                      <el-option
                        v-for="item in field.selectDataList"
                        :key="item[field.selectObj.value]||item.value"
                        :label="item[field.selectObj.label]||item.value"
                        :value="item[field.selectObj.value]||item.value"
                      />
                    </el-select>
                  </el-form-item>
                </template>
                <template v-else-if="field.type==='daterange'">
                  <el-form-item :key="index + '_fi_' + field.field" class="daterange-wrap" :label="field.label" :prop="field.field">
                    <el-date-picker
                      v-model="searchForm[field.field]"
                      type="daterange"
                      :value-format="field.valueFormat||'timestamp'"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                      :append-to-body="false"
                      @change="timeChange"
                    />
                  </el-form-item>
                </template>
                <template v-else>
                  <el-form-item :key="index + '_fi_' + field.field" :label="field.label" :prop="field.field">
                    <input
                      type="text"
                      class="qs-search-input"
                      :value="searchForm[field.field]"
                      :placeholder="`请输入${field.label}`"
                      @input="onFieldInput($event, field.field)"
                    >
                  </el-form-item>
                </template>
              </template>
            </el-form>
            <div :class="['query-footer',searchFields.length % 2===0?'even-footer':'odd-footer']">
              <el-button type="primary" style="font-size: 15px; margin-top: -1px" size="mini" @click="onSearch">搜索</el-button>
              <el-button style="color: #272727; font-size: 14px" size="mini" @click="onReset">重置</el-button>
              <slot name="line" />
              <slot name="btn" />
            </div>
          </div>
          <div class="qs-table" :style="{ width: tableWidth + 'px' }">
            <el-table
              v-if="!hasUnClick"
              ref="dataTable"
              v-loading="loading"
              :data="tableData"
              :highlight-current-row="!multiple"
              max-height="300px"
              :row-class-name="setRowClass"
              @row-click="onSelect"
            >
              <slot />
            </el-table>
            <el-table
              v-else
              ref="dataTable"
              v-loading="loading"
              :data="tableData"
              :highlight-current-row="!multiple"
              max-height="300px"
              :row-class-name="setRowClass2"
              @row-click="onSelect"
            >
              <slot />
            </el-table>
            <el-pagination
              :current-page.sync="pageNo"
              :page-size.sync="pageSize"
              :total="total"
              layout="prev, pager, next"
              style="padding: 10px 0 10px"
              background
              small
              @current-change="currentChange"
            />
          </div>
        </div>
      </dropdown>
    </transition>
  </div>
</template>

<script>
import Dropdown from './SelectDropdown';
import Emitter from 'element-ui/src/mixins/emitter';
import Clickoutside from 'element-ui/src/utils/clickoutside';
import { addResizeListener, removeResizeListener } from 'element-ui/src/utils/resize-event';
// import short from 'short-uuid';
export default {
  name: 'QuerySelect',
  components: { Dropdown },
  directives: { Clickoutside },
  mixins: [Emitter],
  provide() {
    return {
      qsSelect: this,
    };
  },
  props: {
    placeholder: {
      type: String,
      default: '请选择',
    },
    size: {
      type: String,
      default: '',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      required: false,
      default: '',
      type: [String, Array, Number],
    },
    valueKey: {
      required: true,
      type: String,
    },
    valueLabel: {
      required: true,
      type: [String, Array],
    },
    width: {
      type: [String],
      default: '',
    },
    remote: {
      type: Function,
      required: true,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchFields: {
      type: Array,
      default: () => [],
    },
    labelWidth: {
      type: String,
      default: '73px',
    },
    defaultLabel: {
      type: String,
      default: '',
    },
    // 是否可置灰 默认不置灰
    hasUnClick: {
      type: Boolean,
      default: false,
    },
    // 是否选择之前有操作 默认无操作
    beforeAction: {
      type: Boolean,
      default: false,
    },
    // 置灰条件
    unClick: {
      type: Object,
      default() {
        return {
          key: 'remark',
          value: '1',
        };
      },
    },
    // 是否需要初始化回显搜索项值
    needInitSearchForm: {
      type: Boolean,
      default() {
        return false;
      },
    },
    // 初始化搜索项的值集合
    initSearchForm: {
      type: Object,
      default() {
        return {
        };
      },
    },
  },
  data() {
    return {
      selectedLabel: '',
      selectedRow: null,
      selectedRows: [], // 多选
      visible: false,
      placement: 'top',
      tableData: [],
      searchForm: {}, // 查询表单
      pageNo: 1,
      pageSize: 10,
      total: 0,
      showQuery: false,
      inputWidth: 0,
      inputHovering: false,
      loading: false,
      searchFieldsList: [],
      tableWidth: '',
    };
  },
  computed: {
    showClose() {
      const hasValue = this.multiple
        ? Array.isArray(this.value) && this.value.length > 0
        : this.value !== undefined && this.value !== null && this.value !== '';
      const criteria = this.clearable && this.inputHovering && hasValue && !this.disabled;
      return criteria;
    },
    iconClass() {
      return this.visible ? 'arrow-up' : 'arrow-up is-reverse';
    },
    placeholderLabel() {
      if (this.multiple) {
        return this.selectedRows.length ? '' : this.placeholder;
      } else {
        return this.placeholder;
      }
    },
  },
  watch: {
    visible(val) {
      if (!val) {
        this.broadcast('SelectDropdown', 'destroyPopper');
      } else {
        this.broadcast('SelectDropdown', 'updatePopper');
      }
      this.$emit('visible-change', val);
    },
    defaultLabel(val) {
      if (this.multiple) {
        this.setDefaultLabel();
      } else {
        if (val) {
          this.selectedLabel = val;
        } else {
          this.selectedLabel = '';
        }
      }

      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    value(val) {
      if (!val) {
        if (!this.multiple) {
          this.selectedLabel = '';
          this.selectedRow = null;
        } else {
          this.selectedRows = [];
        }
      }
      // else {
      //   this.setDefaultLabel();
      // }
    },
  },
  mounted() {
    addResizeListener(this.$el, this.handleResize);
    const reference = this.$refs.reference;
    this.$nextTick(() => {
      if (reference && reference.$el) {
        this.inputWidth = reference.$el.getBoundingClientRect().width;
      }
    });
    if (this.searchFields && Array.isArray(this.searchFields) && this.searchFields.length) {
      this.showQuery = true;
    } else {
      this.showQuery = false;
    }
    this.setDefaultLabel();
  },
  beforeDestroy() {
    if (this.$el && this.handleResize) removeResizeListener(this.$el, this.handleResize);
  },
  methods: {
    setDefaultLabel() {
      if (this.multiple) {
        this.selectedRows =
          this.value?.map((val, index) => {
            return {
              [this.valueKey]: val,
              [this.valueLabel]: this.defaultLabel.split(',')[index],
            };
          }) || [];
      } else {
        if (this.defaultLabel) {
          this.selectedLabel = this.defaultLabel;
        }
      }
    },
    handleClearClick(event) {
      event.stopPropagation();
      if (!this.multiple) {
        this.selectedLabel = '';
        this.selectedRow = null;
      } else {
        this.selectedRows = [];
      }
      const value = this.multiple ? [] : '';
      this.$emit('input', value);
      this.visible = false;
      this.$emit('clear');
      this.$emit('change', '', null);
    },
    handleClose() {
      this.visible = false;
    },
    handleFocus(event) {
      this.$emit('focus', event);
      // this.getTableData().then(() => {
      //   this.setCurrentRow();
      //   this.broadcast('SelectDropdown', 'updatePopper');
      // });
    },
    toggleMenu() {
      if (!this.disabled) {
        this.visible = !this.visible;
        if (this.visible) {
          if (this.needInitSearchForm) {
            this.searchForm = this.initSearchForm;
          }
          // this.$refs.reference.focus();
          this.getTableData().then(() => {
            this.setCurrentRow();
            this.broadcast('SelectDropdown', 'updatePopper');
          });
          // this.onReset();
        }
      }
    },
    handleBlur(event) {
      this.$emit('blur', event);
    },
    resetInputWidth() {
      this.inputWidth = this.$refs.reference.$el.getBoundingClientRect().width;
    },

    handleResize() {
      this.resetInputWidth();
    },
    getTableData() {
      this.loading = true;
      return this.remote({
        ...this.searchForm,
        // pageNo: this.pageNo,
        // pageSize: this.pageSize,
        current: this.pageNo,
        size: this.pageSize,
      })
        .then((res) => {
          this.tableData = res.data;
          this.total = res.total;
          this.$nextTick(() => {
            if (!this.tableWidth) {
              this.tableWidth = this.$refs.qsContent.offsetWidth || '';
            }
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onFieldInput(e, field) {
      const target = e.target || e.srcElement;
      const value = target.value;
      this.searchForm[field] = value;
    },
    changeSelect(e, field) {
      const value = e || e.target;
      this.searchForm[field] = value;
      this.$forceUpdate();
    },
    // 查询
    onSearch() {
      this.pageNo = 1;
      this.getTableData().then(() => {
        this.setCurrentRow();
        this.broadcast('SelectDropdown', 'updatePopper');
      });
    },
    pageSearch() {
      this.getTableData().then(() => {
        this.setCurrentRow();
        this.broadcast('SelectDropdown', 'updatePopper');
      });
    },
    // 重置
    onReset() {
      const form = this.$refs['searchForm'];
      if (form) {
        form.resetFields();
        this.searchForm = {};
        this.onSearch();
      }
    },
    // 页码变化
    currentChange(page) {
      this.pageNo = page;
      this.pageSearch();
    },
    // 选中操作
    onSelect(row, column, event) {
      // 原始逻辑 不干预
      if (!this.hasUnClick) {
        if (this.multiple) {
          this.doMuiltSelect(row);
        } else {
          if (this.beforeAction) {
            // 此处就是id
            this.$parent.$parent.$parent.checkCarAction(row.id).then(res => {
              if (res) {
                if (this.selectedRow && this.selectedRow[this.valueKey] === row[this.valueKey]) {
                  this.visible = false;
                  return;
                }
                this.selectedRow = row;
                this.$emit('input', row[this.valueKey]);
                this.setSelected(row);
                this.visible = false;
                this.$nextTick(() => {
                  this.$emit('change', row[this.valueKey], row);
                });
              }
            });
          } else {
            // 原有逻辑不干预
            if (this.selectedRow && this.selectedRow[this.valueKey] === row[this.valueKey]) {
              this.visible = false;
              return;
            }
            this.selectedRow = row;
            this.$emit('input', row[this.valueKey]);
            this.setSelected(row);
            this.visible = false;
            // this.$nextTick(() => {
            this.$emit('change', row[this.valueKey], row);
            // });
          }
        }
      } else {
        if (this.beforeAction) {
          if (Number(row[this.unClick.key]) === Number(this.unClick.value)) {
            this.$parent.$parent.$parent.checkCarAction(row.carId).then(res => {
              if (res) {
              // 涉及到需要置灰的操作逻辑 及相关key value判断依据的传入
              // if (Number(row[this.unClick.key]) === Number(this.unClick.value)) {
                if (this.multiple) {
                  this.doMuiltSelect2(row);
                } else {
                  if (this.selectedRow && this.selectedRow[this.valueKey] === row[this.valueKey]) {
                    this.visible = false;
                    return;
                  }
                  this.selectedRow = row;
                  this.$emit('input', row[this.valueKey]);
                  this.setSelected(row);
                  this.visible = false;
                  this.$nextTick(() => {
                    this.$emit('change', row[this.valueKey], row);
                  });
                }
              // }
              }
            });
          }
        } else {
        // 涉及到需要置灰的操作逻辑 及相关key value判断依据的传入
          if (Number(row[this.unClick.key]) === Number(this.unClick.value)) {
            if (this.multiple) {
              this.doMuiltSelect2(row);
            } else {
              if (this.selectedRow && this.selectedRow[this.valueKey] === row[this.valueKey]) {
                this.visible = false;
                return;
              }
              this.selectedRow = row;
              this.$emit('input', row[this.valueKey]);
              this.setSelected(row);
              this.visible = false;
              this.$nextTick(() => {
                this.$emit('change', row[this.valueKey], row);
              });
            }
          }
        }
      }
    },
    setRowClass({ row, index }) {
      if (this.selectedRows.some((val) => val[this.valueKey] === row[this.valueKey])) {
        return 'multi-selected-row';
      }
      return '';
    },
    setRowClass2({ row, index }) {
      if (row.remark !== 1) {
        return 'grey-row';
      }
    },
    // 多选
    doMuiltSelect(row) {
      const index = this.selectedRows.findIndex((val) => val[this.valueKey] === row[this.valueKey]);
      if (index > -1) {
        // 去选
        this.onDeleteTag(null, row);
      } else {
        this.selectedRows.push({
          ...row,
        });
        this.emitMuiltChange(row);
      }
    },
    doMuiltSelect2(row) {
      const index = this.selectedRows.findIndex((val) => val[this.valueKey] === row[this.valueKey]);
      if (index > -1) {
        // 去选
        this.onDeleteTag(null, row);
      } else {
        this.$parent.checkCarAction(row.carId).then(res => {
          if (res) {
            this.selectedRows.push({
              ...row,
            });
            this.emitMuiltChange(row);
          }
        });
      }
    },
    setSelected(row) {
      this.$refs.dataTable?.setCurrentRow(row);
      if (Array.isArray(this.valueLabel)) {
        this.selectedLabel = this.valueLabel
          .map((key) => {
            return row[key];
          })
          .join(' -- ');
      } else {
        this.selectedLabel = row[this.valueLabel];
      }
    },
    setCurrentRow() {
      if (this.value) {
        const item = this.tableData.find((val) => val[this.valueKey] === this.value);
        if (item) {
          this.setSelected(item);
        }
      }
    },
    doDestroy() {
      this.$refs.popper && this.$refs.popper.doDestroy();
    },
    onDeleteTag(e, row) {
      const index = this.selectedRows.findIndex((val) => val[this.valueKey] === row[this.valueKey]);
      this.selectedRows.splice(index >>> 0, 1);
      this.emitMuiltChange();
    },
    // 发送多选绑定值
    emitMuiltChange(nowRow) {
      this.$emit(
        'input',
        this.selectedRows.map((val) => val[this.valueKey]),
      );
      this.$emit(
        'change',
        this.selectedRows.map((val) => val[this.valueKey]),
        this.selectedRows,
        nowRow,
      );
    },
    timeChange(e) {
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.query-select {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.el-pagination{
  text-align: center;
}
.el-tooltip__popper {
  z-index: 9999 !important;
}
.el-select-dropdown{
  padding: 20px 16px;
}
.is-reverse {
  transform: rotate(180deg);
}
::v-deep {
  .el-input__inner {
    cursor: pointer;
  }
  .el-form-item--mini.el-form-item {
    margin-bottom: 0;
  }
  .el-pagination {
    margin-top: 5px;
  }
  .el-table__header {
    font-size: 14px;
  }
  .el-table__row {
    font-size: 14px;
    cursor: pointer;
  }
  .el-table__body tr.current-row > td {
    background: transparent;
    color: #4385ff;
  }
  .el-table__body tr.multi-selected-row > td {
    color: #4385ff;
  }
  .grey-row{
    background: #e8e8e8;
    cursor: not-allowed;
    td{
      color: #606266!important;
    }
    &:hover{
      background: #e8e8e8!important;
      cursor: not-allowed;
    }
  }
}
.qs-content {
  display: flex;
  flex-direction: column;
}
.qs-table {
  padding-bottom: 5px;
  width: 100%;
  position: relative;
}
.qs-query {
  padding: 5px;
  .query-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    >div{
      width: 50%;
      margin-bottom: 8px;
    }
    ::v-deep {
      .el-input__inner {
        cursor: auto;
        border: 1px solid #dcdfe6!important;
      }
      .el-form-item__label {
        font-size: 14px;
        color: #272727;
        margin-top: 1.5px;
      }
    }
    .daterange-wrap{
      ::v-deep {
        .el-date-editor--daterange{
          height: 28px;
          line-height: 28px
        }
        .el-range__icon,.el-range-separator{
          line-height: 20px;
        }
      }
    }
  }
  .query-footer {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 5px 0;
    display: flex;
    justify-content: flex-end;
  }
  .even-footer{
    margin-top: -5px;
  }
  .odd-footer{
    margin-top: -45px;
  }
}
.qs-tags {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  z-index: 1;
  overflow-x: auto;
  &::v-deep {
    .el-tag {
      margin: 2px 0 2px 6px;
    }
    .el-tag.el-tag--info .el-tag__close {
      // background-color: #5a6073;
      color: #5a6073;
    }
    .el-tag.el-tag--info .el-tag__close:hover {
      background-color: #909399;
      color: white;
    }
  }
}
.qs-search-input {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 2px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 28px;
  line-height: 28px;
  outline: none;
  padding: 0 10px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  margin: 1px 0;
  &:hover {
    border-color: #c0c4cc;
  }
  &:focus {
    outline: none;
    border-color: #2773FF;
  }
  &::placeholder {
    color: #c0c4cc;
  }
}
</style>
